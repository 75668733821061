import React, { useState, useRef, useEffect } from "react";
import axios from "axios";

import {
    Card,
    CardBody,
    Col,
    Row,
    Input,
    FormGroup,
    Label,

} from "reactstrap"

import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import withRouter from "../../components/Common/withRouter"
//i18next
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import BootstrapTable from "react-bootstrap-table-next"




const KitechItemTable = (props) => {

    // const [editModal, setEditModal] = useState();
    
    const { SearchBar } = Search

    

    const { selectedItems,setKeyChange, category, setEditModal, editModal, setSelectedRow, setCheckboxState, accessoryItems, checkboxState } = props;

    // console.log(accessoryItems,"selected row")

    function checkboxFormatter(cell, row, rowIndex) {
        // console.log("checked row/unchecked row")
        return (

            <FormGroup check>

                <Label check>
                    <Input
                        // id="checkbox1"
                        // ref={checkboxRef}
                        disabled={ row.available.in_unit === 0 && row.available.in_sub_unit === 0  }
                        checked={checkboxState[rowIndex] || false}
                        style={{ border: "1px solid black" }}
                        type="checkbox"
                        onChange={() => { setKeyChange(prev => prev += 1); setSelectedRow({ ...row, rowInd: rowIndex, rowId: row._id  }); setCheckboxState(prev => { prev[rowIndex] = prev[rowIndex] ? !prev[rowIndex] : true; return prev }) }}
                    />

                </Label>
            </FormGroup>
        )
    }
    // modal table columns accessory items
    const columnsAccessoryItems = [

        {
            dataField: "_id",
            hidden: true
        },
        {
            text: props.t(""),
            dataField: "checkbox",
            formatter: checkboxFormatter,
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "2%" }
            },
        },
        {
            text: props.t("#"),
            formatter: (cell, row, rowIndex) => {
                return rowIndex + 1
            },
            // dataField: "_id",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "2%" }
            },
        },
        {
            text: props.t("Item id"),
            //formatter: employeeFormatter,
            dataField: "_id",
            sort: true,
            hidden: true,
            headerStyle: (colum, colIndex) => {
                return { width: "8%" }
            },
        },
        {
            text: props.t("Image"),
            dataField: "image_url",
            formatter: (cell, row) => {
                // console.log(row)
                return (
                    <img className="avatar-md rounded-circle img-thumbnail" src={row.image_url} alt={row.name + "image"} />
                    // <p>{"image"}</p>
                )
            },
            // dataField: "image_url",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "8%" }
            },
        },
        {
            text: props.t("Item"),
            //formatter: employeeFormatter,
            dataField: "name",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "8%" }
            },
        },
        {
            text: props.t("Available QTY"),

            formatter: (cell, row) => {
                return (
                    // console.log(row)
                    row.available.in_unit === 0 && row.available.in_sub_unit === 0 ? <p style={{color:"#E57373", fontWeight:"bold"}}>Out Of Stock</p> :
                    <p>{`${row.available.in_unit} ${row.unit} ${row.sub_unit? row.available.in_sub_unit : ""} ${row.sub_unit ? row.sub_unit : ""}`}</p>
                )
            },
            dataField: "available.in_unit",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "8%" }
            },
        },

    ]

    return <>
        <Row>
            <Col lg={12}>
                <Card>
                    <CardBody>
                        {/* <CardTitle></CardTitle> */}
                        <ToolkitProvider
                            keyField={"_id" + new Date()}
                            data={accessoryItems.filter(item => item.section === category)}
                            columns={columnsAccessoryItems}
                            search
                        >
                            {propst => (
                                <div>
                                    <Row>
                                        <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                                            <SearchBar
                                                className="form-control"
                                                {...propst.searchProps}
                                                // style={{ width: "340px" }}
                                                placeholder="search"
                                            />
                                        </Col>
                                    </Row>

                                    <BootstrapTable
                                        {...propst.baseProps}
                                        noDataIndication={props.t("No data to display.")}
                                        striped
                                        hover
                                        condensed
                                    />
                                </div>
                            )}
                        </ToolkitProvider>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </>
}

export default withRouter(connect(null, {})(withTranslation()(KitechItemTable)))