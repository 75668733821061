import React, { useState, useEffect, useRef } from "react"
import { useLocation } from "react-router-dom"
import "./allBooking.css"
import axios from "axios"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Badge,
  CardTitle,
  Input,
  FormGroup,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { AvField, AvForm } from "availity-reactstrap-validation"
import swal from "sweetalert2"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import cellEditFactory from "react-bootstrap-table2-editor"

import BootstrapTable from "react-bootstrap-table-next"
import filterFactory, { selectFilter,textFilter } from "react-bootstrap-table2-filter"
import Loader from "../../helpers/Loader"
// Redux
import { connect } from "react-redux"
import withRouter from "../../components/Common/withRouter"
//i18next
import Select from "react-select"
import { withTranslation } from "react-i18next"
import moment from "moment"
import "./allBooking.css"
import BackgroundImage from "../../assets/images/Background.png"
import { useNavigate } from "react-router-dom"
import showNotification, { showWarning } from "../../helpers/show_notificaton"
import { postSubmitForm } from "../../helpers/backend_helper"
import preventValueChangeOnScroll from "../../helpers/PreventValueOnScroll"

const CheckOut = props => {
  const { SearchBar } = Search

  const [bookings, setBookings] = useState([])
  const [bookingsFilter, setBookingsFilter] = useState([])
  const refContainer = useRef()

  const [checkoutDetailsModal, setCheckoutDetailsModal] = useState(false)
  const [bookingId, setBookingID] = useState("")
  const [customerDetails, setCustomerDetails] = useState({})

  // Package details
  const [packageDetails, setPackageDetails] = useState([])
  const [startTime, setStartTime] = useState("")
  const [endTime, setEndTime] = useState("")
  const [totalPrice, setTotalPrice] = useState(0)
  const [originalTotalPrice, setTotalOriginalPrice] = useState(0)
  //Game details and Item details
  const [gameDetails, setGameDetails] = useState([])
  const [gameTotalPrice, setGameTotalPrice] = useState(0)
  const [itemDetails, setItemDetails] = useState([])
  const [itemTotalPrice, setITemTotalPrice] = useState(0)
  const [totalGameTime, setTotalGameTime] = useState(null)

  // Payment details
  // const [paidByCash, setPaidByCash] = useState(0)
  // const [paidByCard, setPaidByCard] = useState(0)
  const [paidByWallet, setPaidByWallet] = useState(0)
  const [isWalletEligible, setISWalletEligible] = useState(false)
  const [paidAmount, setPaidAmount] = useState(0)

  const [availableBalance, setAvailableBalance] = useState("")
  const [discountPercentage, setDiscountPercentage] = useState(0)
  const [postdiscountAmount, setPostdiscountAmount] = useState(0)
  const [discountInput, setDiscountInput] = useState("")
  const [discountTracker, setDiscountTracker] = useState(false)
  const [remarks, setRemarks] = useState("")

  const [remainingPaidAmount, setRemainingPaidAmount] = useState(0);
  const [changeAmount, setChangeAmount] = useState(0);
  //Bill Split
  const [isBillsplit, setIsBillSplit] = useState(false)
  const [billSplitBetween, setBillSplitBetween] = useState(0)
  const [billSplitAmount, setBillSplitAmount] = useState(0)
  const [differenceAmount, setDifferenceAmount] = useState(0)

  // Payment Methods
  const [paymentMethods, setPaymentMethods] = useState([])
  const [selectedPaymentMethods, setSelectedPaymentMethods] = useState([])
  const [formFieldValues, setFormFieldValues] = useState({})
  const [paymentDetails, setPaymentDetails] = useState([])

  //branch details
  const [branchDetails, setBranchDetails] = useState({})
  const [isCheckOutModalOpen, setIsCheckOutModalOpen] = useState(false)
  const [is_personDisappear, setPersonDisappear] = useState(false)

 const [selectedTable, setSelectedTable] = useState("All");
 const [selectedGame, setSelectedGame] = useState("All");

  useEffect(() => {
    fetchBookings()
    fetchPaymentMethods()
  }, [])

  useEffect(()=>{
    if(selectedGame!==""){
      loadInProcessTables()
    }
  },[selectedGame])

  const [inprocessTables, setInprocessTables] = useState()
  const loadInProcessTables = async () => {
    setInprocessTables((prev)=>prev=[])
    let payload= {
      game_name:selectedGame&&selectedGame
    }
    let url = process.env.REACT_APP_BASEURL + "sales/inprocess_game_tables"
    let response = await postSubmitForm(url, payload)
    if (response.status === 1) {
      setInprocessTables(response.data)
    } else {
      showNotification(response.message, "Error")
    }
  }

  const fetchBookings = async () => {
    try {
      Loader(true)
      const response = await axios.post(
        process.env.REACT_APP_BASEURL + "bookings/get_in_process",
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      )
      // console.log("Respone", response.data.data)
      if (response.data.status === 1) {
        Loader(false)
        const filterData = response.data.data.filter(
          inprocess =>
            inprocess?.is_item_only === false &&
            inprocess?.is_package_only === false
        )
        setBookings(filterData)
        setBookingsFilter(filterData)
      } else {
        Loader(false)
        // Handle error
      }
    } catch (error) {
      Loader(false)
      // Handle error
    }
  }

  //Payment Method
  const fetchPaymentMethods = async () => {
    try {
      // Loader(true)
      const response = await axios.post(
        process.env.REACT_APP_BASEURL + "payment_methods/getall",
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      )

      if (response.data.status === 1) {
        Loader(false)
        if (customerDetails[0]?.is_wallet_eligible === false) {
          const active = response.data.data.filter(
            e => e.name !== "Wallet" && e.is_active === true
          )
          active.sort((a, b) => a.name.localeCompare(b.name));
          setPaymentMethods(active)
        } else {
          Loader(false)
          const active = response.data.data.filter(e => e.is_active === true)
          active.sort((a, b) => a.name.localeCompare(b.name));
          setPaymentMethods(active)
        }
      } else {
        Loader(false)
      }
    } catch (error) {
      Loader(false)
    }
  }

  useEffect(() => {
    fetchPaymentMethods()
  }, [customerDetails])
  //CheckOut API
  const [isNoChange, setIsNoChange] = useState(false)
  const [is_Refund, setIs_Refund] = useState(false)
  const [refundAmount, setRefundAmount] = useState(0)
  const [refundRemarks, setRefundRemarks] = useState("")
  const [refundType, setRefundType] = useState("")
  function handleValidSubmit(e, v) {
    let total = Math.round((itemTotalPrice+gameTotalPrice)-postdiscountAmount)
    let extraFromSplit = (billSplitAmount*billSplitBetween)-total 
    const payload = {
      remarks: remarks,
      customer_details: customerDetails[0],
      booking_id: bookingId,
      branch_details: branchDetails,
      game_details: gameDetails,
      item_details: itemDetails,
      package_details: packageDetails,
      item_total_price: itemTotalPrice,
      game_total_price: Math.round(gameTotalPrice),
      total_amount: (itemTotalPrice + gameTotalPrice),
      total_time: totalGameTime,
      paid_amount: +totalPrice,
      discount: discountPercentage,
      change_amount: !isNoChange ? finalChange : 0  ,
      final_amount: +totalPrice,
      discount_amount: +postdiscountAmount || 0,
      payment_details: paymentDetails,
      is_bill_split: isBillsplit,
      bill_split_between: +billSplitBetween,
      bill_split_amount: +billSplitAmount,
      // extra_amount: isBillsplit ? extraFromSplit: 0,
      difference_amount: isNoChange? finalChange : 0,
    }
    if (isBillsplit && extraFromSplit > 0) {
      payload.extra_amount = extraFromSplit;
  } else {
      payload.extra_amount = 0;
  }
    // console.log(payload, "payload")
    // return
    let url = process.env.REACT_APP_BASEURL
    axios
      .post(
        url + `sales/check_out`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      )
      .then(response => {
        if (response.data.status === 1) {
          swal.fire("Success", response.data.message, "success")
          closeCheckoutDetailsModal()
          fetchBookings()
          const invoiceData = response.data.data
          const invoiceUrl = `${window.location.origin}/invoice_details?booking_id=`+invoiceData?.booking_id
          const newTab = window.open(`${invoiceUrl}`, "_blank")
          if (newTab) {
            newTab.focus()
          }
        } else {
          swal.fire("Error", response.data.message, "error")
        }
      })
      .catch(error => {
        console.error("Error:", error)
        if (error.response) {
          swal.fire("Error", error.response.data.message, "error")
        } else {
          swal.fire("Error", "An error occurred while check-out.", "error")
        }
      })
  }

  function handleValidSubmitUnpaid(e, v) {
    const payload = {
      remarks: remarks || "",
      customer_details: customerDetails[0],
      booking_id: bookingId,
      branch_details: branchDetails,
      game_details: gameDetails,
      item_details: itemDetails,
      package_details: packageDetails,
      payment_details: paymentDetails,
      item_total_price: itemTotalPrice,
      game_total_price: gameTotalPrice,
      total_amount: (itemTotalPrice + gameTotalPrice),
      total_time: totalGameTime,
    }

    let url = process.env.REACT_APP_BASEURL

    axios
      .post(
        url + `sales/checkout_unpaid`,

        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      )
      .then(response => {
        if (response.data.status === 1) {
          swal.fire("Success", response.data.message, "success")
          closeCheckoutDetailsModal()
          fetchBookings()
        } else {
          swal.fire("Error", response.data.message, "error")
        }
      })
      .catch(error => {
        console.error("Error:", error)
        if (error.response) {
          swal.fire("Error", error.response.data.message, "error")
        } else {
          swal.fire("Error", "An error occurred while check-out.", "error")
        }
      })
  }

  const fetchUpdatedData = async (row) => {
    try {
      let url = process.env.REACT_APP_BASEURL + "bookings/get_in_process"
      const response = await postSubmitForm(url,{})
      if (response.status === 1) {
        const filterData = response.data.filter(
          inprocess =>
            inprocess?.is_item_only === false &&
            inprocess?.is_package_only === false
        )
        setBookings(filterData);
        const Row = filterData?.filter(bkg=>row._id===bkg?._id)
        openCheckoutDetailsModal(Row[0])
      } 
    } catch (error) {
      showNotification(error.message,"Error")
    }
  };


  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      text: "S.N.",
      formatter: (cell, row, rowIndex) => {
        const rowNumber = rowIndex + 1
        return rowNumber
      },
      headerStyle: (colum, colIndex) => {
        return { width: "3%" }
      },
    },
    {
      text: "Customer Name",
      dataField: "customer_details.name",
      sort: false,
      // hidden: true,
      // formatter: (customerDetails, row) => {
      //   if (customerDetails && customerDetails.name && customerDetails.customer_type && customerDetails.mobile) {
      //     return `${customerDetails.name} [${customerDetails.customer_type}]`;
      //   }
      //   return "";
      // },
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    
    {
      text: "Check-In",
      dataField: "check_in",
      formatter: (cell, row) => {
        if (row.check_in) {
          return moment(row.check_in).format("YYYY-MM-DD HH:mm:ss")
        } else {
          return "Not yet checked in "
        }
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "3%" }
      },
    },
     {
      text: props.t("Games"),
      dataField: "game_details",
      formatter: (gameDetails, row) => {
        if (gameDetails && gameDetails.length > 0) {
          return gameDetails.map((game, id) => (
            <Badge key={id} color="primary" className="me-1">
              {game.game.name}
            </Badge>
          ))
        }
        return (
          <Badge style={{ cursor: "not-allowed" }} color="secondary">
            No Games
          </Badge>
        )
      },
      sort:true,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },

    {
      text: props.t("Tables"),
      dataField: "game_details",
      formatter: (gameDetails, row) => {
        if (gameDetails && gameDetails.length > 0) {
          return gameDetails.map((game, id) => (
            <Badge key={id} color="primary" className="me-1">
              {game.table_number}
            </Badge>
          ))
        }
        return (
          <Badge style={{ cursor: "not-allowed" }} color="secondary">
            No Tables
          </Badge>
        )
      },
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },

    {
      text: "Duration",
      formatter: (cell, row) => {
        const checkIn = moment(row.check_in)
        const endTime = moment(row.end_time)
        // console.log(moment(row.start_time).format("HH:mm:ss"));
        // console.log(moment(row.end_time).format("HH:mm:ss"));
        if (checkIn.isValid() && endTime.isValid()) {
          const duration = moment.duration(endTime.diff(checkIn))
          const hours = duration.hours()
          const minutes = duration.minutes()

          if (hours > 0) {
            return hours + " hours " + minutes + " minutes"
          } else if (minutes > 0) {
            return minutes + " minutes"
          } else {
            return "N/A"
          }
        } else {
          return "N/A"
        }
      },
      hidden: false,
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "2%" }
      },
    },
    {
      text: "Check Out",
      dataField: "check_out",
      formatter: (cell, row) => {
        if (row.check_out) {
          return moment(row.check_out).format("YYYY-MM-DD HH:mm:ss")
        } else {
          return "Not yet checked out "
        }
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "3%" }
      },
    },
    // {
    //   text: props.t("Items"),
    //   dataField: "item_details",
    //   formatter: (itemDetails, row) => {
    //     if (itemDetails && itemDetails.length > 0) {
    //       return itemDetails.map((item, id) => (
    //         <Badge key={id} color="info" className="me-1">
    //           {item.name}
    //         </Badge>
    //       ))
    //     }

    //     return (
    //       <Badge style={{ cursor: "not-allowed" }} color="secondary">
    //         No Items
    //       </Badge>
    //     )
    //   },
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "5%" }
    //   },
    // },
    // {
    //   text: props.t("Packages"),
    //   dataField: "package_details",
    //   formatter: (packageDetails, row) => {
    //     if (packageDetails && packageDetails.length > 0) {
    //       return packageDetails.map((packages, id) => (
    //         <Badge className="me-1" key={id} color="success">
    //           {packages.package_id}
    //         </Badge>
    //       ))
    //     }
    //     return (
    //       <Badge className="cursor-not-allowed" color="secondary">
    //         No Packages
    //       </Badge>
    //     )
    //   },
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "5%" }
    //   },
    // },

    {
      text: props.t("Booking Status"),
      dataField: "booking_status",
      filter: selectFilter({
        options: [
          { value: "In Process", label: "In Process" },
          { value: "Unpaid", label: "Unpaid" },
        ],
        defaultValue: "In Process",
        placeholder: "All",
      }),
      style: (cell, row) => {
        return {
          textAlign: "center",
        }
      },
      formatter: (cell, row) => {
        if (row.booking_status === "In Process") {
          return <Badge color="warning">{row.booking_status}</Badge>
        } else if (row.booking_status === "Unpaid") {
          return <Badge color="danger">{row.booking_status}</Badge>
        }
      },
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: "Game Total Price",
      dataField: "game_total_price",
      sort: false,
      hidden: true,
      formatter: (cell, row) => cell || "0",
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: "Item Total Price",
      dataField: "item_total_price",
      sort: false,
      hidden: true,
      formatter: (cell, row) => cell || "0",
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: "Grand Total Price",
      dataField: "total_price",
      sort: false,
      style: (cell, row) => {
        return {
          textAlign: "center",
        }
      },
      formatter: (cell, row) => cell || "0",
      headerStyle: (colum, colIndex) => {
        return { width: "6%" }
      },
    },

    {
      dataField: "",
      text: props.t("Check Out"),
      formatter: (cell, row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "15px",
          }}
        >
          {row.booking_status && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* <i
                className="fa fa-user"
                style={{
                  color: "#556EE6",
                  fontSize: "17px",
                  cursor: "pointer",
                  marginTop: "4.3px",
                }}
                onClick={() => openCheckoutDetailsModal(row)}
              ></i> */}
              <i
                className="fa fa-shopping-cart"
                style={{
                  color: "blue",
                  fontSize: "17px",
                  cursor: "pointer",
                  marginTop: "4.3px",
                  marginRight: "5px",
                }}
                onClick={ () => {
                fetchUpdatedData(row); 
               }}
              ></i>
            </div>
          )}

          {/* <i
             style={{color:"black", fontSize:"16px", cursor:"pointer"}}
             className="fa fa-edit"
              
            >

            </i> */}
        </div>
      ),
      sort: false,
      hidden: false,
      headerStyle: (colum, colIndex) => {
        return { width: "3%" }
      },
    },
  ]

  const location = useLocation()
  const booking = location.state?.booking
  const add = JSON.parse(localStorage.getItem("instantcheckout"))

  const openCheckoutDetailsModal = row => {
    // console.log(row, "ROWWWW")
    setBookingID(row._id)
    setRemarks(row.remarks)
    setIsCheckOutModalOpen(true)
    const customerDetailsObj = {
      name: row.customer_details.name,
      customer_type: row.customer_details.customer_type,
      mobile: row.customer_details.mobile,
      is_wallet_eligible: row.customer_details.is_wallet_eligible,
      wallet: row.customer_details.wallet,
      _id: row.customer_details._id,
    }
    setCustomerDetails([customerDetailsObj])
    const branchDetailObj = {
      address: row.branch_details?.address,
      branch_id: row.branch_details?.branch_id,
      email: row.branch_details?.email,
      mobile: row.branch_details?.mobile,
      name: row.branch_details?.name,
    }
    setBranchDetails(branchDetailObj)
    const formattedPackageDetails = row.package_details.map(pkg => {
      const formattedItems = pkg.item_details.map(item => ({
        available: {
          in_unit: item.available.in_unit,
          in_sub_unit: item.available.in_sub_unit,
        },
        category: item.category,
        cost_price: item.cost_price,
        formula: item.formula,
        is_household_item: item.is_household_item,
        image_url: item.image_url,
        item_id: item.item_id,
        name: item.name,
        quantity: {
          in_unit: item.quantity.in_unit,
          in_sub_unit: item.quantity.in_sub_unit,
        },
        new_available: {
          in_unit: item.new_available.in_unit,
          in_sub_unit: item.new_available.in_sub_unit,
        },
        section: item.section,
        selling_price: item.selling_price,
        threshold: item.threshold,
        unit: item.unit,
        sub_unit: item.sub_unit,
      }))

      const formattedGames = pkg.game_details.map(game => ({
        description: game.description,
        image: game.image,
        table_id: game.table_id,
        game_id: game.game_id,
        name: game.name,
        table_number: game.table_number,
        table_start_time: game.table_start_time,
        table_end_time: game.table_end_time,
        duration: game.duration,
        requested_duration: game.requested_duration,
        new_duration: game.new_duration,
        price: game.price,
      }))

      return {
        // available: pkg.available,
        // label: pkg.label,
        package_id: pkg.package_id,
        package_name: pkg.package_name,
        // price: pkg.price,
        // validity: pkg.validity,
        // value: pkg.value,
        // _id: pkg._id,
        item_details: formattedItems,
        game_details: formattedGames,
      }
    })
    setPackageDetails(formattedPackageDetails)
    const formattedGameDetails = row.game_details?.map(gameDetail => ({
      capacity: gameDetail.capacity,
      game: {
        name: gameDetail.game.name,
        price_for_friend: gameDetail.game.price_for_friend,
        price_for_regular: gameDetail.game.price_for_regular,
        price_for_vip: gameDetail.game.price_for_vip,
        sub_total: gameDetail.game.sub_total,
        duration: gameDetail.game.duration,
        table_start_time: gameDetail.game.table_start_time,
        table_end_time: gameDetail.game.table_end_time,
        available: gameDetail.game.available,
        description: gameDetail.game.description,
        game_id: gameDetail.game.game_id,
        image: gameDetail.game.image,
      },

      is_active: gameDetail.is_active,
      is_occupied: gameDetail.is_occupied,
      label: gameDetail.label,
      table_number: gameDetail.table_number,
      table_type: gameDetail.table_type,
      value: gameDetail.value,
      _id: gameDetail._id,
    }))
    setGameDetails(formattedGameDetails)
    const formattedItemDetails = row.item_details?.map(itemDetail => ({
      available: {
        in_unit: itemDetail?.available.in_unit,
        in_sub_unit: itemDetail?.available.in_sub_unit,
      },
      category: itemDetail?.category,
      formula: itemDetail?.formula,
      image: itemDetail?.image,
      is_household_item: itemDetail?.is_household_item,
      item_id: itemDetail?.item_id,

      name: itemDetail.name,
      new_available: {
        in_unit: itemDetail.new_available.in_unit,
        in_sub_unit: itemDetail.new_available.in_sub_unit,
      },
      price: itemDetail.price,
      quantity: {
        in_unit: itemDetail.quantity.in_unit,
        in_sub_unit: itemDetail.quantity.in_sub_unit,
      },
      sub_total: itemDetail.sub_total,
      section: itemDetail.section,
      threshold: itemDetail.threshold,
      unit: itemDetail.unit,
      sub_unit: itemDetail.sub_unit,
    }))
    setItemDetails(formattedItemDetails)
    // Calculate total game time
    const totalGameTime = formattedGameDetails.reduce(
      (total, game) => total + game.game.duration,
      0
    )

    setTotalGameTime(totalGameTime)

    setGameTotalPrice(row.game_total_price)
    setITemTotalPrice(row.item_total_price || 0)

    setStartTime(row.start_time)
    setEndTime(row.end_time)
    setTotalPrice(row.total_price)
    setRemainingPaidAmount(row.total_price)
    setTotalOriginalPrice(row.total_price)
    setAvailableBalance(row.customer_details.wallet)
    setISWalletEligible(row.customer_details.is_wallet_eligible)
    setDiscountInput("")
    setDiscountPercentage(0)
    setPostdiscountAmount(0)
    setDiscountTracker(false)
    // setPaidByCash(0)
    // setPaidByCard(0)
    setSelectedPaymentMethods([])
    setFormFieldValues({})
    setIsBillSplit(false)
    setCheckoutDetailsModal(true)
    setBillSplitAmount(0)
    setBillSplitBetween(0)
    setDifferenceAmount(0)
    setPaymentDetails([])
    setChangeAmount(0)
    setFinalChange(0)
    // setShowChangeAmount(0)
  }


  const closeCheckoutDetailsModal = () => {
    setRemainingPaidAmount(0)
    setDiscountInput("")
    setRefundType("")
    setRefundAmount(0)
    setRefundRemarks("")
    setIsNoChange(false)
    setIs_Refund(false)
    setDiscountPercentage(0)
    setPostdiscountAmount(0)
    setIsBillSplit(false)
    // setPaidByCash(0)
    // setPaidByCard(0)
    setSelectedPaymentMethods([])
    setFormFieldValues({})
    setDiscountTracker(false)
    setBillSplitBetween(0)
    setBillSplitAmount(0)
    setDifferenceAmount(0)
    setChangeAmount(0)
    setFinalChange(0)
    // setShowChangeAmount(0)
    setCheckoutDetailsModal(false)
    setPersonDisappear(false)
    localStorage.removeItem("instantcheckout")
    isCheckoutClose()
  }

  const applyDiscount = async () => {
    if (!discountInput || typeof discountInput !== "string") {
      swal.fire({
        title: "Invalid Coupon Code",
        text: "Please enter a valid coupon code.",
        icon: "error",
      })
      return
    }

    try {
      const response = await axios.post(
        process.env.REACT_APP_BASEURL + "coupons/getall",
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      )

      if (response.data.status === 1) {
        const enteredCoupon = response.data.data.find(
          coupon =>
            coupon.coupon_code === discountInput && coupon.is_active === true
        )

        if (enteredCoupon) {
          const couponDiscount = enteredCoupon.discount_percent
          setDiscountPercentage(couponDiscount)
          const discountedTotal = totalPrice * (1 - couponDiscount / 100)
          const discountAmount =(totalPrice - discountedTotal).toFixed(2)
          setPostdiscountAmount(+discountAmount)
          setTotalPrice(Math.round(discountedTotal))
          setRemainingPaidAmount(Math.round(discountedTotal))
          setDiscountTracker(true)

          swal.fire({
            title: "Coupon Applied",
            text: `Coupon "${discountInput}" applied successfully for ${couponDiscount}% discount.`,
            icon: "success",
          })
        } else {
          swal.fire({
            title: "Coupon Not Found",
            text: `Coupon "${discountInput}" is not valid.`,
            icon: "error",
          })
        }
      } else {
        swal.fire("Error", response.data.message, "error")
      }
    } catch (error) {
      if (error.response) {
        swal.fire("Error", error.response.data.message, "error")
      } else {
        swal.fire(
          "Error",
          "An error occurred while applying the coupons.",
          "error"
        )
      }
    }
  }


  const checkoutCustomerColumn = [
    {
      text: "Name",
      dataField: "name",
      // formatter: (cell, row) => cell
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },

    {
      text: "Customer Type",
      dataField: "customer_type",
      sort: false,
      // formatter: (cell, row) => cell || "0",
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },

    {
      text: "Customer Mobile",
      dataField: "mobile",
      sort: false,
      // formatter: (cell, row) => cell || "0",
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
  ]

  const checkoutGameColumn = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      text: "S.N.",
      formatter: (cell, row, rowIndex) => {
        const rowNumber = rowIndex + 1
        return rowNumber
      },
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "3%" }
      },
    },
    {
      text: "Name",
      dataField: "game.name",
      // formatter: (cell, row) => cell
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
      editable: false,
    },
    {
      text: "Start Time",
      dataField: "game.table_start_time",
      formatter: (cell, row) => {
        return moment(row.game.table_start_time).format("HH:mm")
      },
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
      editable: false,
    },

    {
      text: "Duration",
      dataField: "game.duration",
      sort: false,
      // formatter: (cell, row) => cell || "0",
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
      editable: false,
      validator: (newValue, row, column) => {
        if (+newValue < 30) {
          return {
            valid: false,
            message: "Minimum Playing Time is 30 Minutes",
          }
        }
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: "Only numbers are allowed.",
          }
        }
        if (newValue % 1 != 0) {
          return {
            valid: false,
            message: "Decimals not allowed.",
          }
        }
        return true
      },
      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) => (
        <input
          style={{
            width: "50px",
            height: "40px",
            border: "1px solid lightGray",
            padding: "5px",
            borderRadius: "5px",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          }}
          type="number"
          defaultValue={value}
          onBlur={e => {
            // handleQuantityChange(e, rowIndex, row, editorProps);
            handleDurationChange(e, rowIndex, row, editorProps)
            editorProps.onUpdate(+e.target.value)
            // setEnterQtyArr(prev => { prev = { ...prev, [row._id]: row.quantity.in_unit === 0 ? false : true } })
          }}
          onFocus={e => e.target.select()} // Retain focus on input field
          onWheel={preventValueChangeOnScroll}
          autoFocus
        />
      ),
    },
    {
      text: "End Time",
      dataField: "game.table_end_time",
      formatter: (cell, row) => {
        return row.game.table_end_time ? moment(row.game.table_end_time).format("HH:mm") : "N/A"
      },
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
      editable: false,
    },
    {
      text: "Game Price",
      // dataField: "game.price",
      // dataField: "game.price_for_regular",
dataField:  customerDetails[0]?.customer_type === "Friend"
        ? "game.price_for_friend"
        : customerDetails[0]?.customer_type === "Regular"
          ? "game.price_for_regular"
          : "game.price_for_vip",

      sort: false,
      // formatter: (cell, row) => cell || "0",
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
      editable: false,
    },
    {
      text: "Sub Total",
      dataField: "game.sub_total",
      sort: false,
      // formatter: (cell, row) => cell || "0",
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
      editable: false,
    },
  ]

  function handleDurationChange(e, rowIndex, row, editorProps) {
    const { value } = e.target
    // const updatedData = { ...row }
    // const rowData = updatedData
    // rowData.duration = +value

    editorProps.onUpdate(+value)
    // console.log(row, "row")

    let price = 0

    if (customerDetails[0]?.customer_type === "Friend") {
      price = row.game.price_for_friend
    } else if (customerDetails[0]?.customer_type === "Regular") {
      price = row.game.price_for_regular
    } else if (customerDetails[0]?.customer_type === "VIP") {
      price = 0
    }

    let a = [...gameDetails]
    // console.log(a,'aaaaaaaa')

    let initialSubTotal
    a.forEach(e => {
      if (e._id === row._id) {
        initialSubTotal = e.game.sub_total

        if (row.game.name.includes("VIP")) {
          if (+value > 60) {
            let sixtyMin = 60
            let rem = +value - sixtyMin

            e.game.sub_total = (150 / 60) * sixtyMin + (100 / 60) * rem

            e.game.sub_total = +e.game.sub_total.toFixed(2)
          } else {
            e.game.sub_total = (150 / 60) * +value

            e.game.sub_total = +e.game.sub_total.toFixed(2)
          }
        } else {
          if (+value < 30) {
            e.game.sub_total = (price / 60) * 30
          } else {
            e.game.sub_total = (price / 60) * +value
            e.game.sub_total = +e.game.sub_total.toFixed(2)
          }
        }
      }
    })

    const priceDifference =
      a.find(e => e._id === row._id).game.sub_total - initialSubTotal

    // console.log(priceDifference, "price difference")

    setGameDetails(a)
    setTotalPrice(prevTotalPrice => prevTotalPrice + priceDifference)
    setRemainingPaidAmount(prevTotalPrice => prevTotalPrice + priceDifference)
    //  const durationDifference = +value - row.duration;

    const updatedTotalGameTime = gameDetails.reduce(
      (total, game) => total + game.game.duration,
      0
    )
    const updatedTotalGamePrice = gameDetails.reduce(
      (total, game) => total + game.game.sub_total,
      0
    )

    setTotalGameTime(updatedTotalGameTime)
    setGameTotalPrice(updatedTotalGamePrice)
  }

  const checkoutItemColumn = [
    {
      dataField: "item_id",
      hidden: true,
    },
    {
      text: "S.N.",
      formatter: (cell, row, rowIndex) => {
        const rowNumber = rowIndex + 1
        return rowNumber
      },
      headerStyle: (colum, colIndex) => {
        return { width: "3%" }
      },
    },
    {
      text: "Name",
      dataField: "name",
      // formatter: (cell, row) => cell
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },

    {
      text: "Section",
      dataField: "section",
      sort: false,
      // formatter: (cell, row) => cell || "0",
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: " Quantity (in unit)",
      dataField: "quantity.in_unit",
      sort: false,
      // formatter: (cell, row) => cell || "0",
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: "Quantity (in sub unit)",
      dataField: "quantity.in_sub_unit",
      sort: false,
      // formatter: (cell, row) => cell || "0",
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: "Sub Total",
      dataField: "sub_total",
      sort: false,
      // formatter: (cell, row) => cell || "0",
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
  ]

  const bookedPackageDetailscolumns = [
    {
      dataField: "_id",
      hidden: true,
    },
    // {
    //   dataField: "_id",
    //   formatter: (cell, row, rowIndex) => {
    //     return rowIndex + 1
    //   },
    //   text: "S.N",
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "1%" }
    //   },
    // },
    {
      dataField: "package_name",
      text: "Package ID",
    },
    {
      dataField: "item_details",
      text: "Item Details",
      formatter: (cell, row) => {
        if (cell.length === 0) {
          return <div>No items found</div>
        }

        return (
          <ul>
            {cell.map((item, index) => (
              <li key={index}>
                <strong>{item.name}</strong>
                <br />
                Requested Quantity: {item.quantity.in_unit}
                {"  "}
                {item.unit}
                {"  "}
                {item.quantity.in_sub_unit}
                {"  "}
                {item.sub_unit}
                <br />
                Section: {item.section}
              </li>
            ))}
          </ul>
        )
      },
    },
    {
      dataField: "game_details",
      text: "Game Details",
      formatter: cell => {
        if (cell.length === 0) {
          return <div>No games found</div>
        }

        return (
          <ul>
            {cell.map((game, index) => (
              <li key={index}>
                {("Game")} : <strong>{game.name}</strong> <br />
                {("Table")} : <strong>{game.table_number}</strong>
                <br />
                {props.t("Duration")}: <b> {game.requested_duration}  {("minutes")}</b>
                <br />
                {props.t("Game Time")} : <strong> {moment(game.table_start_time).format("HH:mm")} - {moment(game.table_end_time).format("HH:mm")} </strong>
              </li>
            ))}
          </ul>
        )
      },
    },
  ]

  const calculateFinalPaidAmount = () => {
    let totalPaidAmount = 0

    selectedPaymentMethods.forEach(selectedOption => {
      const fieldName = `paymentMethod_${selectedOption.value}`
      const value = parseFloat(formFieldValues[fieldName]) || 0
      totalPaidAmount += value
    })

    const walletAmount = parseFloat(paidByWallet) || 0
    totalPaidAmount += walletAmount

    return totalPaidAmount.toFixed(2)
  }
  const handleSplitBill = () => {
    setIsBillSplit(!isBillsplit)
  }

  const handleBillSplitBetweenChange = e => {
    const value = Number(e.target.value)

    if (value > 0) {
      setBillSplitBetween(value)

      const splitAmount = Math.ceil(totalPrice / value)
      const withoutCeilSplit = totalPrice / value
      const difference = ((splitAmount - withoutCeilSplit) * value).toFixed(2)

      setDifferenceAmount(+difference)
      setBillSplitAmount(splitAmount)

      const newTotalPrice = totalPrice + parseFloat(difference)
      setTotalPrice(newTotalPrice)
      setRemainingPaidAmount(newTotalPrice)
    } else {
      setBillSplitBetween(0)
      setDifferenceAmount(0)
      setBillSplitAmount(0)
      setTotalPrice(originalTotalPrice)
      setRemainingPaidAmount(originalTotalPrice)
    }
  }

  const handlePaymentMethodChange = selectedOptions => {
    const newPaymentDetails = selectedOptions.map(selectedOption => {
      const fieldName = `paymentMethod_${selectedOption.value}`
      const existingDetail = paymentDetails.find(
        detail => detail._id === selectedOption.value
      )
      const value = existingDetail ? existingDetail[selectedOption.label] : ""

      return {
        _id: selectedOption.value,
        [selectedOption.label]: value !== "" ? value : 0,
      }
    })

    setPaymentDetails(newPaymentDetails)

    const totalPaidAmount = newPaymentDetails.reduce((total, paymentMethod) => {
      for (const key in paymentMethod) {
        if (key !== "_id") {
          total += parseFloat(paymentMethod[key]) || 0
        }
      }
      return total
    }, 0)

    const newRemainingPaidAmount =
      totalPrice - totalPaidAmount - parseFloat(paidByWallet)
    setRemainingPaidAmount(newRemainingPaidAmount.toFixed(2))

    const unselectedOptions = selectedPaymentMethods.filter(
      option => !selectedOptions.includes(option)
    )
    unselectedOptions.forEach(unselectedOption => {
      const fieldName = `paymentMethod_${unselectedOption.value}`
      setFormFieldValues(prevValues => ({
        ...prevValues,
        [fieldName]: "",
      }))
    })

    setSelectedPaymentMethods(selectedOptions)
  }

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  }


  useEffect(() => {
    if (booking) {
      openCheckoutDetailsModal(booking)
     
    }
  }, [booking])

  useEffect(() => {
    if (add) {
      openCheckoutDetailsModal(add)
    }
  }, [])

  const totalPaidAmount = paymentDetails.reduce((total, paymentMethod) => {
    for (const key in paymentMethod) {
      if (key !== "_id") {
        total += parseFloat(paymentMethod[key]) || 0
      }
    }
    return total
  }, 0)

const handleChangeAmount = ()=>{
  useEffect(()=>{
    let changeAfterRefund = 0;
    if(remainingPaidAmount < 0){
      setChangeAmount(prev=>(prev = Math.abs(remainingPaidAmount)))
      if(refundAmount>changeAmount){
        changeAfterRefund = totalPaidAmount - refund;
        setFinalChange(changeAfterRefund)
      }else{
        setFinalChange((prev)=>(prev = Math.abs(remainingPaidAmount)))
      }
    }else{
      setChangeAmount((prev)=>(prev = 0))
      setFinalChange((prev)=>(prev = 0))
    }
  // }, [Math.abs(remainingPaidAmount)])
  }, [remainingPaidAmount])
}
 const [finalChange, setFinalChange] = useState(0)
const handleRefundAmount = (e) =>{
  let refund = +e.target.value
  setRefundAmount(refund)
  let changeAfterRefund = 0;
  if(refund>changeAmount){
    changeAfterRefund = totalPaidAmount - refund;
    setFinalChange(changeAfterRefund)
  }else {
    changeAfterRefund = changeAmount- refund 
    setFinalChange(changeAfterRefund)
  }
}


useEffect(()=>{
 isCheckoutOpen()
},[isCheckOutModalOpen])

const checkoutIsClose = {
  booking_id: bookingId,
  newValue: false,
}
const isCheckoutOpen = async()=>{
  let url = process.env.REACT_APP_BASEURL
 await axios
    .post(
      url + `bookings/isCheckoutOpen`,
      {
        booking_id : bookingId,
        newValue : true
     },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    )
    .catch(error => {
      console.error("Error:", error)

    })
}

const isCheckoutClose = async()=>{
  let url = process.env.REACT_APP_BASEURL
 await axios
    .post(
      url + `bookings/isCheckoutOpen`,
      checkoutIsClose,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    )
    .catch(error => {
      console.error("Error:", error)

    })
}

// Filter game table
const filterBookingsByTable = (table) => {
  if (table === 'All') {
    setBookingsFilter(bookings); 
  } else {
    const filtered = bookings.filter(booking =>
      booking.game_details.some(game => game.table_number === table)
    );
    setBookingsFilter(filtered); 
  }
};


useEffect(()=>{
filterBookingsByTable(selectedTable)
},[selectedTable])

//Games Filter
const gameNameSet = new Set();
bookings.forEach(booking => {
  booking.game_details.forEach(game => {
    gameNameSet.add(game.game.name);
  });
});

const gameNames = Array.from(gameNameSet).sort((a, b) => a.toLowerCase().localeCompare(b));

const filterBookingsByGameName = (gameName) => {
  if (gameName === 'All') {
    setBookingsFilter(bookings); 
  } else {
    const filtered = bookings.filter(booking =>
      booking.game_details.some(game => game.game.name === gameName)
    );
    setBookingsFilter(filtered); 
  }
};

useEffect(()=>{
  filterBookingsByGameName(selectedGame)
},[selectedGame])

  function handleRefundChecked(e) {
    setRefundAmount(0)
    setRefundType("")
    setRefundRemarks("")
    setIs_Refund(e.target.checked)
  }



  function CheckAmounts (e,v){
    const isVipCustomer = customerDetails[0]?.customer_type === "VIP";
    const isWalletPayment = selectedPaymentMethods[0]?.label === "Wallet";
    const walletBalance = customerDetails[0]?.wallet||0;
    const RemainingCreditLimit = walletBalance <=0 ? 3000 + walletBalance : 0
    const newBalace = walletBalance <=0 ? RemainingCreditLimit : walletBalance+3000
    const walletEntry = paymentDetails.find(entry => 'Wallet' in entry);
    const walletAmount = walletEntry ? walletEntry.Wallet : 0;  
  // return
  if (is_personDisappear === true) {
    handleValidSubmitUnpaid()
    return
  }else{
    if (isVipCustomer) {
      handleValidSubmit(e, v);
    } else if (isWalletPayment) {
      if (walletAmount > newBalace) {
        showWarning("Wallet credit balance limit reached", "warning");
      }  else if (totalPaidAmount >=  +totalPrice ) {
        handleValidSubmit();
      }  else {
        showNotification("Total amount does not match entered amount.", "Error");
      }
    } else {
      if (totalPaidAmount >= totalPrice) {
        handleValidSubmit(e, v);
      } else {
        showNotification("Total amount does not match entered amount.", "Error");
      }
    }
  }
  }
  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title="Bookings"
            alternateTitle="Check Out"
            breadcrumbItem1="Check Out"
          />

          <Row>
            <Col>
              <Card>
                <CardBody
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  {/* <CardTitle className="fs-3" >All Bookings</CardTitle> */}

                  <ToolkitProvider
                    keyField="_id"
                    columns={columns}
                    data={bookingsFilter || []}
                    search
                  // isGlobalFilter={true}
                  // isAddUserList={true}
                  // customPageSize={10}
                  >
                    {propst => (
                      <div>
                        <AvForm>
                          <Row>
                            <label className="form-label">
                              {"Search In Check Out"}
                            </label>
                            <Col lg={2} md={2} sm={2}>
                              <SearchBar
                                {...propst.searchProps}
                              // style={{ width: "340px" }}
                              />
                            </Col>
                            <Col lg={6} md={6} sm={6}></Col>
                          
                            <Col lg={2} md={2} sm={2} style={{marginTop:"-35px"}}>
                           <Label>{props.t("Game")}</Label>
                              <select
                                name="status"
                                className="form-control"
                                value={selectedGame}
                                placeholder="--Select Table--"
                                onChange={(e) => {
                                  setSelectedGame(e.target.value);
                                }}
                              >
                              <option value="All">All</option>
                             {gameNames.map(games => (
                                <option key={games} value={games}>
                                  {games}
                                </option>
                              ))}
                              </select>
                            </Col>
                            <Col lg={2} md={2} sm={2}style={{marginTop:"-35px"}}>
                           <Label>{props.t("Game Tables")}</Label>
                              <select
                                name="status"
                                className="form-control"
                                value={selectedTable}
                                placeholder="--Select Table--"
                                onChange={(e) => {
                                  setSelectedTable(e.target.value);
                                }}
                              >
                              <option value="All">All</option>
                             {inprocessTables?.map(tableNumber => (
                                <option key={tableNumber} value={tableNumber}>
                                  {tableNumber}
                                </option>
                              ))}
                              </select>
                            </Col>
                            
                          </Row>
                        </AvForm>
                        <BootstrapTable
                          {...propst.baseProps}
                          filter={filterFactory()}
                          noDataIndication={props.t("No data to display.")}
                          striped
                          // expandRow={expandRow}
                          condensed
                        // pagination={paginationFactory(options)}
                        />
                      </div>
                    )}
                  </ToolkitProvider>

                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* CheckOut Modal details  */}
          <Modal
            size="lg"
            isOpen={checkoutDetailsModal}
            toggle={closeCheckoutDetailsModal}
          >
            <ModalHeader toggle={closeCheckoutDetailsModal}>
              <h5>{props.t("Check Out")} 🛒</h5>
            </ModalHeader>
            <ModalBody>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <>
                      <div>
                        <span>{props.t("Date and Time")}:</span>
                      </div>
                      <Row>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <h6 style={{ color: "#62AEF2" }}>
                            {`Start Time: ${moment(startTime).format(
                              "YYYY-MM-DD HH:mm"
                            )} - End Time: ${endTime ? moment(endTime).format(
                              "YYYY-MM-DD HH:mm"
                            ):"N/A"}`}
                          </h6>
                        </div>
                      </Row>

                      <br />
                     
                      <Col lg={8}>
                        <h6>{props.t("Customer Details")}:</h6>
                      </Col>
                      <Col>
                        <ToolkitProvider
                          keyField="_id"
                          columns={checkoutCustomerColumn}
                          data={customerDetails || []}
                        // search
                        >
                          {propst => (
                            <div>
                              <BootstrapTable
                                {...propst.baseProps}
                                noDataIndication={props.t(
                                  "No data to display."
                                )}
                                striped
                                hover
                                condensed
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                      </Col>
                
                      <br />
                      <Col lg={6}>
                        <h6>{props.t("Game Details")}</h6>
                      </Col>
                      <Col>
                        <ToolkitProvider
                          keyField="_id"
                          columns={checkoutGameColumn}
                          data={gameDetails || []}
                        // search
                        >
                          {propst => (
                            <div>
                              <BootstrapTable
                                {...propst.baseProps}
                                noDataIndication={props.t(
                                  "No data to display."
                                )}
                                cellEdit={cellEditFactory({
                                  mode: "click",
                                  blurToSave: true,
                                })}
                                striped
                                hover
                                condensed
                              />
                              <Row>
                                <Col
                                  lg={12}
                                  style={{
                                    display: "flex",
                                    gap: "5px",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <span>{props.t("Game Total Price")}:</span>
                                  <span
                                    style={{
                                      color: "black",
                                      fontWeight: "bolder",
                                    }}
                                  >
                                    {" "}
                                    {`QR ${gameTotalPrice ? gameTotalPrice : 0}`}{" "}
                                  </span>{" "}
                                  &nbsp; &nbsp;
                                  <span>{props.t("Total Game Time")}:</span>
                                  <span
                                    style={{
                                      color: "black",
                                      fontWeight: "bolder",
                                    }}
                                  >
                                    {" "}
                                    {`${totalGameTime} minutes`}{" "}
                                  </span>
                                </Col>
                              </Row>
                            </div>
                          )}
                        </ToolkitProvider>
                      </Col>
                      <br />
                      <Col lg={6}>
                        <h6>{props.t("Item Details")}:</h6>
                      </Col>
                      <Col>
                        <ToolkitProvider
                          keyField="item_id"
                          columns={checkoutItemColumn}
                          data={itemDetails || []}
                        // search
                        >
                          {propst => (
                            <div>
                              <BootstrapTable
                                {...propst.baseProps}
                                noDataIndication={props.t(
                                  "No data to display."
                                )}
                                striped
                                hover
                                condensed
                              />
                              <Row>
                                <Col
                                  lg={12}
                                  style={{
                                    display: "flex",
                                    gap: "5px",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                 {itemTotalPrice && (
                                  <>
                                   <span>{props.t("Item Total Price")}:</span>
                                  <span
                                    style={{
                                      color: "black",
                                      fontWeight: "bolder",
                                    }}
                                  >
                                    {`QR ${itemTotalPrice}`}{" "}
                                  </span>
                                  </>
                                 )}
                                </Col>
                              </Row>
                            </div>
                          )}
                        </ToolkitProvider>
                      </Col>
                      <br />
                      <Col lg={6}>
                        <h6>{props.t("Package Details")}:</h6>
                      </Col>
                      <Col>
                        <ToolkitProvider
                          keyField="_id"
                          columns={bookedPackageDetailscolumns}
                          data={packageDetails || []}
                        // search
                        >
                          {propst => (
                            <div>
                              <BootstrapTable
                                {...propst.baseProps}
                                noDataIndication={props.t(
                                  "No data to display."
                                )}
                                striped
                                hover
                                condensed
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                      </Col>
                    </>

                    <br />
                    <AvForm
                      onValidSubmit={() => {CheckAmounts()}}
                      ref={refContainer}
                    >
                      <Row>
                        <Col
                          lg={6}
                          style={{
                            // marginLeft: "33%",
                            marginBottom: "1.5rem",
                          }}
                        >
                          <div
                            style={{
                              maxWidth: "99%",
                              paddingTop: "5px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",

                              gap: "5px",

                              boxShadow:
                                "rgba(3, 102, 214, 0.3) 0px 0px 0px 3px",
                            }}
                          >
                            <h6
                              style={{
                                color: "#0b1f32",
                                fontWeight: "bold",
                              }}
                            >
                              Remaining Amount To Pay:
                            </h6>

                            <h6
                              style={{
                                color:
                                  remainingPaidAmount <= 0
                                    ? "#36B94D"
                                    : "#FA395D",
                                fontWeight: "bold",
                                fontSize: "17px",
                              }}
                            >
                              {`QR ${remainingPaidAmount == 0
                                  ? ""
                                  : remainingPaidAmount < 0
                                    ? "+"
                                    : "-"
                                }${Math.abs(remainingPaidAmount)} ${remainingPaidAmount < 0? "": ""}` }
                            </h6>
                          </div>
                        </Col>
                    

                        <Col
                          lg={6}
                          style={
                            {
                              // marginLeft: "33%",
                            }
                          }
                        >
                          <div
                            style={{
                              maxWidth: "99%",
                              paddingTop: "5px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",

                              gap: "5px",

                              boxShadow:
                                "rgba(3, 102, 214, 0.3) 0px 0px 0px 3px",
                            }}
                          >
                            <h6
                              style={{
                                color: "#0b1f32",
                                fontWeight: "bold",
                              }}
                            >
                              Amount To Pay:
                            </h6>
                            <h6
                              style={{
                                color: "#FA395D",
                                fontWeight: "bold",
                                fontSize: "17px",
                              }}
                            >
                              {`QR ${+totalPrice?.toFixed(2)}`}
                            </h6>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg={5}>
                          <span
                            style={{
                              fontSize: "10px",

                              visibility: `${discountTracker && postdiscountAmount > 0
                                  ? "unset"
                                  : "hidden"
                                }`,
                            }}
                            className="text-muted"
                          >
                            {props.t("Amount Reduced By")}:{"  "}
                            <span
                              style={{
                                color: "red",
                                fontWeight: "600",
                              }}
                            >
                              {postdiscountAmount}
                            </span>
                          </span>
                          <div
                            style={{
                              height: "30px",
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              gap: "18px",
                            }}
                          >
                            <Label>Discount Coupon</Label>
                            <Button
                              style={{
                                width: "100px",
                                height: "25px",
                                marginTop: "-10px",

                                boxShadow: "none",
                                transition: "transform 0.3s ease",
                              }}
                              onMouseEnter={e => {
                                e.target.style.transform = "scale(1.05)"
                              }}
                              onMouseLeave={e => {
                                e.target.style.transform = "scale(1)"
                              }}
                              className="btn btn-info btn-sm "
                              onClick={applyDiscount}
                              disabled={discountTracker}
                            >
                              Apply Coupon
                            </Button>
                          </div>

                          <AvField
                            id="discountInput"
                            name="discount"
                            // label={props.t("Discount Coupon")}
                            type="text"
                            placeholder={props.t("Enter Coupon Code ")}
                            value={discountInput}
                            onChange={e => setDiscountInput(e.target.value)}
                          />
                        </Col>
                        <Col size={3}>
                        <div className="mt-5" >
<Button
                            type="button"
                            onClick={handleSplitBill}
                            style={{
                              position: "relative",
                              overflow: "hidden",
                              backgroundColor: "#fdd005",
                              border: "none",
                              color: "black",
                              fontSize: "14px",
                              fontWeight: "500",
                              width: "128px",

                              marginLeft: "12px",
                              boxShadow:
                                "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                              transition: "transform 0.3s ease",
                            }}
                            onMouseEnter={e => {
                              e.target.style.transform = "scale(1.05)"
                            }}
                            onMouseLeave={e => {
                              e.target.style.transform = "scale(1)"
                            }}
                          >
                            <i className="fa fa-users"> </i>
                            &nbsp; {isBillsplit ? "Cancel" : "Split Bill"}
                            <div
                              style={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                right: "0",
                                bottom: "0",
                                background:
                                  "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                pointerEvents: "none",
                              }}
                            ></div>
                          </Button>
                        </div>
                        </Col>
                        <Col lg={4}>
                          <FormGroup
                            check
                            className="mt-5 mb-2"
                            style={{ display: customerDetails[0]?.customer_type === "VIP" ? "none" : "flex", justifyContent: "center" }}
                          >
                            <Label check className="ms-5">
                              <Input
                                style={{ border: "0.5px solid black" }}
                                type="checkbox"
                                checked={isNoChange}
                                onChange={e =>
                                  setIsNoChange(e.target.checked)
                                }
                              />
                              {props.t("No Change")}
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                      {/* <Row style={{ marginBottom: "0.8rem" }}>
                     

                      
                      </Row> */}

                      {isBillsplit && (
                        <>
                          <Row>
                            <Col lg={6}>
                              <Label>Split Between</Label>
                              <AvField
                                id="billSplitBetween"
                                name="billSplitBetween"
                                type="number"
                                placeholder="Enter number of people"
                                value={billSplitBetween}
                                onChange={handleBillSplitBetweenChange}
                                onWheel={preventValueChangeOnScroll}
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage:
                                      "This field is required for bill split",
                                  },
                                }}
                              />
                            </Col>
                            <Col lg={6}>
                              <Label>Split Amount</Label>
                              <AvField
                                id="billSplitAmount"
                                name="billSplitAmount"
                                type="number"
                                placeholder=" "
                                value={billSplitAmount || "0"}
                                readOnly
                                disabled
                              />
                            </Col>
                          </Row>
                        </>
                      )}
                      <Row>
                        <Col lg={6} style={{ marginBottom: "0.8rem" }}>
                          <Label>Select Payment Method</Label>
                          <span
                            style={{
                              fontSize: "10px",

                              visibility: `${selectedPaymentMethods[0]?.label === "Wallet"
                                  ? "unset"
                                  : "hidden"
                                }`,
                            }}
                            className="text-muted ms-2"
                          >
                            {props.t("Balance")}:{"  "}
                            <span
                              style={{
                                color: "red",
                                fontWeight: "600",
                              }}
                            >
                              {customerDetails[0]?.wallet}
                            </span>
                          </span>
                          <Select
                            options={paymentMethods.map(method => ({
                              value: method._id,
                              label: method.name,
                              image_url: method.image_url,
                            }))}
                            placeholder={props.t("Select Payment Method")}
                            isMulti
                            isDisabled={customerDetails[0]?.customer_type==="VIP"}
                            value={selectedPaymentMethods}
                            onChange={handlePaymentMethodChange}
                          />
                        </Col>
                      </Row>

                      <Row>
                        {selectedPaymentMethods.map(selectedOption => (
                          <Col lg={3} key={selectedOption.value}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={selectedOption.image_url}
                                alt={selectedOption.label}
                                style={{
                                  marginRight: "4px",
                                  width: "21px",
                                  height: "21px",
                                }}
                              />
                              <label>{`Paid by ${selectedOption.label}`}</label>
                            </div>
                            <AvField
                              name={`paymentMethod_${selectedOption.value}`}
                              type="number"
                              value={
                                formFieldValues[
                                `paymentMethod_${selectedOption.value}`
                                ]
                              }
                              validate={{
                                required: { value: true },
                                pattern: {
                                  value: "^[0-9a-zA-Z]+$",
                                  errorMessage: props.t(
                                    "Cannot use space/special characters."
                                  ),
                                },
                              }}
                              placeholder="0"
                              onChange={e => {
                                const fieldName = `paymentMethod_${selectedOption.value}`
                                const newValue = parseFloat(e.target.value)
                                setFormFieldValues(prevValues => ({
                                  ...prevValues,
                                  [fieldName]: newValue,
                                }))

                                setPaymentDetails(prevDetails => {
                                  const paymentMethodId = selectedOption.value
                                  const paymentMethodName = selectedOption.label
                                  const updatedDetails = [...prevDetails]
                                  const existingDetailIndex =
                                    updatedDetails.findIndex(
                                      detail => detail._id === paymentMethodId
                                    )

                                  if (existingDetailIndex !== -1) {
                                    updatedDetails[existingDetailIndex][
                                      paymentMethodName
                                    ] = newValue
                                  } else {
                                    const newDetail = {
                                      _id: paymentMethodId,
                                      [paymentMethodName]: newValue,
                                    }
                                    updatedDetails.push(newDetail)
                                  }
                                  const totalPaidAmount = updatedDetails.reduce(
                                    (total, paymentMethod) => {
                                      for (const key in paymentMethod) {
                                        if (key !== "_id") {
                                          total +=
                                            parseFloat(paymentMethod[key]) || 0
                                        }
                                      }
                                      return total
                                    },
                                    0
                                  )

                                  const newRemainingPaidAmount =
                                    totalPrice -
                                    totalPaidAmount -
                                    parseFloat(paidByWallet)
                                  setRemainingPaidAmount(
                                    newRemainingPaidAmount.toFixed(2)
                                  )

                                  return updatedDetails
                                })
                              }}
                              onWheel={preventValueChangeOnScroll}
                            // validate={{
                            //   required: {
                            //     value: true,
                            //     errorMessage:
                            //       "This field is required for payment",
                            //   },
                            // }}
                            />
                          </Col>
                        ))}
                      </Row>

                      {/* Refund logics */}
                      {is_Refund && (
                        <Row>
                          <Col lg={4} md={4} >
                            <AvField
                              name="refund_amount"
                              label={props.t("Refund Amount")}
                              value={refundAmount}
                              onChange={(e)=>handleRefundAmount(e)}
                              placeholder="Enter a refund amount"
                              type="number"
                              validate={{
                                required: { value: is_Refund },
                                max: {value: totalPaidAmount,
                                  errorMessage: props.t("Refund amount cannot exceed the remaining paid amount")}
                              }}
                            errorMessage={props.t("Enter a refund amount")}
                            />
                          </Col>
                         <Col lg={4} md={4}>
                        <AvField type="select"
                              validate={{
                                required: { value: is_Refund },
                              }} name="refund_type" label="Refund" value={refundType} onChange={(e)=>setRefundType(e.target.value)}>
                          <option value="">-- select refund type--</option>
                          <option value="Cash">By cash</option>
                          <option value="Card">By card</option>
                        </AvField>
                        </Col> 
                          <Col lg={4} md={4} >
                            <AvField
                              name="refund_remarks"
                              label={props.t("Remarks ")}
                              value={refundRemarks}
                              validate={{
                                required: { value: is_Refund },
                              }}
                              onChange={(e)=>setRefundRemarks(e.target.value)}
                              placeholder="Enter remarks if any"
                              type="textarea"
                            />
                          </Col>
                          </Row>
                      )}

                      <Row>
                        <Col
                          lg={6}
                          style={{
                            paddingTop: "15px",
                          }}
                        >
                          <AvField
                            name="remarks"
                            label={props.t("Remarks")}
                            placeholder="Enter Remarks (Optional)"
                            type="textarea"
                            value={remarks}
                            onChange={e => setRemarks(e.target.value)}
                            validate={{
                              required: { value: false },
                            }}
                          // errorMessage={props.t("Select Game")}
                          />
                        </Col>
                        <Col
                          lg={3}
                          style={{
                            paddingTop: "15px",
                          }}
                        >
                          <AvField
                            id="changeAmount"
                            name="changeAmount"
                            label={props.t("Change Amount")}
                            type="number"
                            placeholder={remainingPaidAmount < 0 ? 0 : "N/A"}
                            value={isNoChange ? 0 : finalChange}
                            onChange={handleChangeAmount()}
                            disabled
                          
                          />
                        </Col>
                        <Col
                          lg={3}
                          style={{
                            paddingTop: "15px",
                          }}
                        >
                          <AvField
                            id="paidAmount"
                            name="paidAmount"
                            label={props.t("Final Paid Amount")}
                            type="text"
                            placeholder={props.t("Enter final amount paid ")}
                            value={calculateFinalPaidAmount()}
                            readOnly
                            disabled
                            onChange={e => setPaidAmount(e.target.value)}
                          // validate={{
                          //   required: {
                          //     value: true,
                          //     errorMessage: props.t(
                          //       "This field is required."
                          //     ),
                          //   },
                          //   pattern: {
                          //     value: "^\\d+(\\.\\d{1,2})?$",
                          //     errorMessage: props.t(
                          //       "Only numbers and decimals upto two places are allowed."
                          //     ),
                          //   },
                          // }}
                          />
                        </Col>
                       
                      </Row>
                      <Row >
                        <Col style={{ width: "10px" }}>
                          <FormGroup
                            check
                            className="mt-2 mb-2"
                            style={{ display: customerDetails[0]?.customer_type === "VIP" ? "none" : "flex", justifyContent: "center" }}
                          >
                            <Label check>
                              <Input
                                style={{ border: "0.5px solid black" }}
                                type="checkbox"
                                checked={is_personDisappear}
                                onChange={e =>
                                  setPersonDisappear(e.target.checked)
                                }
                              />
                              {props.t("mark as unpaid")}
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row >
                        <hr />
                        {is_personDisappear === false ? (
                          <Button
                            style={{
                              width: "124px",
                              margin: "auto",
                              boxShadow:
                                "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                              transition: "transform 0.3s ease",
                            }}
                            onMouseEnter={e => {
                              e.target.style.transform = "scale(1.05)"
                            }}
                            onMouseLeave={e => {
                              e.target.style.transform = "scale(1)"
                            }}
                            type="submit"
                            onSubmit={handleValidSubmit}
                            className="btn  btn-dark waves-effect"
                          >
                            <i className="bx bx-cart  font-size-18 align-middle me-2"></i>{" "}
                            {props.t("Check Out")}
                          </Button>
                        ) : (
                          <Button
                            style={{
                              width: "124px",
                              margin: "auto",
                              background: "#e00001",
                              boxShadow:
                                "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                              transition: "transform 0.3s ease",
                              border: "none",
                            }}
                            onMouseEnter={e => {
                              e.target.style.transform = "scale(1.05)"
                            }}
                            onMouseLeave={e => {
                              e.target.style.transform = "scale(1)"
                            }}
                            type="submit"
                            onSubmit={handleValidSubmitUnpaid}
                          // className="btn  btn-dark waves-effect"
                          >
                            <i className="bx bx-cart  font-size-18 align-middle me-2"></i>{" "}
                            {props.t("Unpaid")}
                          </Button>
                        )}
                      </Row>

                    </AvForm>
                  </CardBody>
                </Card>
              </Col>

              {/* ... (other modal content) */}
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(connect(null, {})(withTranslation()(CheckOut)))
