export function validateItemQuantities(payload) {
    const validationErrors = [];

    payload.item_details.forEach(item => {    
        if (item.quantity?.in_unit==0  && item.quantity?.in_sub_unit==0) {
                validationErrors.push({
                    itemId: item.item_id,
                    name: item.name,
                    error: `Please enter a valid quantity for <strong>  ${item.name}  </strong>`
                });
        } 
        if (item.formula && item.formula > 0 && !item.is_household_item) {
            const availableInSubUnits = item.available.in_unit * item.formula + item.available.in_sub_unit;
            const requestedInSubUnits = item.quantity.in_unit * item.formula + item.quantity.in_sub_unit;

            if (requestedInSubUnits > availableInSubUnits) {
                validationErrors.push({
                    itemId: item.item_id,
                    name: item.name,
                    error: `Insufficient quantity. <strong> Available: ${item.available.in_unit} ${item.unit} and ${item.available.in_sub_unit} ${item.sub_unit}, Requested: ${item.quantity.in_unit} ${item.unit} and ${item.quantity.in_sub_unit} ${item.sub_unit}  </strong>`
                });
            }
        } 
        else {
            if (item.quantity.in_unit > item.available.in_unit && !item.is_household_item) {
                validationErrors.push({
                    itemId: item.item_id,
                    name: item.name, 
                    error: `Insufficient quantity. <strong> Available: ${item.available.in_unit} ${item.unit}, Requested: ${item.quantity.in_unit} ${item.unit}  </strong>`
                });
            }
        }
    });
    return {
        isValid: validationErrors.length === 0,
        errors: validationErrors
    };
}