import React, { useEffect, useRef, useState } from "react"
import {
  Card, CardBody, Col, Row, Container, Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardTitle, FormGroup, Badge, ModalHeader, ModalBody, Modal
} from "reactstrap"
import classnames from "classnames";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {  AvField, AvForm, AvRadio, AvRadioGroup } from "availity-reactstrap-validation"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import withRouter from "../../components/Common/withRouter"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit//dist/react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import { postSubmitForm } from "../../helpers/backend_helper"
import showNotification from "../../helpers/show_notificaton"
import { sortBy } from "lodash"
import exportFromJSON from "export-from-json"
import filterFactory, { selectFilter, textFilter } from 'react-bootstrap-table2-filter';
import moment from "moment"
import paginationFactory from 'react-bootstrap-table2-paginator';
import Loader from '../../helpers/Loader'
//Flatpickr
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
// import CheckBox from "../../helpers/checkbox"
import BackgroundImage from "../../assets/images/Background.png"
import styles from "../Dashboard/Badge.module.css";
import { Pagination } from "../../components/Common/Pagination";
import preventValueChangeOnScroll from "../../helpers/PreventValueOnScroll";
import { TableLoader } from "../Reports/SalesReport/ItemSales";

const AddCustomer = props => {
  //meta title
  document.title = "Add Customer | Rendezvous - Game Parlour"
  const refContainer = useRef(null)
  const [selectedRow, setSelectedRow] = useState()
  const [totalPage, setTotalPage] = useState(1)
  const [allCustomer, setAllCustomer] = useState([])
  const [editModal, setEditModal] = useState()
  const [viewModal, setViewModal] = useState()
  const [viewPackageModal, setViewPackageModal] = useState()
  const [viewNestedPackageModal, setViewNestedPackageModal] = useState()
  const [viewItemTransactionModal, setviewItemTransactionModal] = useState()
  const [viewGameTransactionModal, setviewGameTransactionModal] = useState()
  const [Row2, setRow2] = useState([])
  const [packageRow, setPackageRow] = useState([])
  const [nestedPackageItemRow, setNestedPackageItemRow] = useState([])
  const [nestedPackageGameRow, setNestedPackageGameRow] = useState([])
  const [gameTranscation, setGameTransaction] = useState([])
  const [itemTranscation, setItemTransaction] = useState([])
  const [nestedRow, setNestedRow] = useState()
  const [PackageDetails, setPackageDetails] = useState([])
  const [is_wallet, setIs_Wallet] = useState(false)
  const [allPackages, setAllPackages] = useState([])
  const [is_active, setis_active] = useState()
  //for pagination
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [loading, setLoading] = useState(false);
  //Tabs
  const [verticalActiveTab, setverticalActiveTab] = useState("1");
  const [is_wallet_update, setIs_wallet_update] = useState()
  const [type, setType] = useState("");
  useEffect(() => {
    loadAllPackages()
  }, [packageRow])

  useEffect(() => {
    loadAllCustomers(null);
  }, [pageNumber]);

  const { SearchBar } = Search
  const [qatarID, setQatarID] = useState()
  const [passport, setPassport] = useState()

  // ------------------------------------------------------------------------------

  //Tabs Function
  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab);
    }
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} - {to} of {size} records.
    </span>
  );

  const options = {
    paginationSize: 10,
    pageStartIndex: 1,
    // hideSizePerPage: true,
    // hidePageListOnlyOnePage: true,
    onPageChange: (page, sizePerPage) => {
      setPageNumber(page);
      setPageSize(sizePerPage);
    },
    firstPageText: "First",
    prePageText: "Prev",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
       {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
      {
        text: "300",
        value: 300,
      },
      {
        text: "All",
        value: allCustomer && allCustomer.length,
      },
    ],
    hideSizePerPage: true,
    ignoreSinglePage: true,
  };


  function formatDate(date) {
    if (!date) return null
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = "0" + month
    if (day.length < 2) day = "0" + day

    return [year, month, day].join("-")
  }


  const handleDownload = async () => {
    const fileName = "All Customer's";
    const exportType = "xls";

    // let data_to_export = allCustomer;
    let data_to_export = type!=="" 
        ? allCustomer.filter(item=>item.customer_type===type) 
        : allCustomer

    if (data_to_export) {
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v) {
        data.push({
          Name: v.name,
          Mobile: v.mobile,
          Email: v.email,
          Qatar_id: v.qatar_id,
          Passport: v.passport,
          Created_On: moment(v.createdAt).format("YYYY-MM-DD"),
          Created_by: v.customerCreatedBy?.employee_name,
          Wallet: v.is_wallet_eligible?"Yes":"No",
          Date_of_birth: formatDate(v.date_of_birth),
          Address: v.address,
          Customer_Type: v.customer_type,
        });
      });
      exportFromJSON({ data, fileName, exportType });
    }
  }
  // console.log(PackageDetails);
  // console.log(allPackages);
  // console.log(packageRow);
  // console.log(nestedPackageItemRow);

  // /for round image view
  function showPhotoFormatter(cell, row) {
    return (
      <>
        <img
          src={row.image_url || row.image + "?" + new Date() + "=" + new Date()}
          alt={row.name}
          className="avatar-md rounded-circle img-thumbnail"
        />
        <span
          style={{ cursor: "pointer" }}
          className="font-size-12 badge-soft-info badge badge-success badge-pill"
        >
        </span>
      </>
    )
  }

  // console.log(selectedRow);
  function editFormatter(cell, row) {
    return (
      <span className="text-info d-flex justify-content-between" style={{ gap: "10px" }}>
        <i
          className="bx bxs-edit fs-5"
          title="Click to Edit"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setEditModal(!editModal)
            setis_active(row.is_active)
            setIs_wallet_update(row.is_wallet_eligible)
            //  setUpadteisVIPCustomer(row.is_vip_member)
            setSelectedRow(row)
          }}
        ></i>
        {/* <i
          className="fas fa-file-alt ms-3 fs-5 text-primary"
          title="View Bookings"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setViewModal(!viewModal)
            setSelectedRow(row)
            setRow2(row.booking_details)
          }}
        ></i> */}
        <i
          className="fas fa-box fs-5 text-warning "
          title="View Packages"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setViewPackageModal(!viewPackageModal)
            setPackageRow(row)
            setPackageDetails(row.package_details)

            // console.log(row);
            loadAllPackages(row)
          }}
        ></i>
        {/* <i
          className="form-check form-switch form-switch-md ms-3 fs-5 text-danger"
          title="Click to Delete"
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleValidDelete(row)
          }}
        ></i> */}

        <i>

          <div className="form-switch " dir="ltr">
            <input
              type="checkbox"
              style={{ height: "15px", width: "30px" }}
              title={row.is_active ? "Click to Deactivate ." : "Activate"}
              className="form-check-input"
              id={"customSwitchsize1" + row._id}
              onClick={() => {
                handleValidDelete(row)
              }}
              checked={row.is_active}
            />
            <label
              title="Click to change status."
              className="custom-control-label"
              htmlFor={"customSwitch1" + row._id}
              style={{ fontWeight: "100", cursor: "pointer" }}
            ></label>
          </div>
        </i>

        {/* {row.is_active ? (
          <span className="font-size-5 badge-soft-success badge badge-success badge-pill">
            {props.t("Active")}
          </span>
        ) : (
          <span className="font-size-5 badge-soft-danger badge badge-danger badge-pill">
            {props.t("Inactive")}
          </span>
        )} */}

      </span>
    )
  }

  function editFormatterPackage(cell, row) {
    return (
      <span className="text-info" style={{ display: "flex" }}>
        <i
          className="fas fa-file-alt ms-3 fs-5 text-primary"
          title="View Packages"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setViewNestedPackageModal(!viewNestedPackageModal)
            setSelectedRow(row)
            setNestedPackageItemRow(row.package_details ? row.package_details.item_details : null)
            setNestedPackageGameRow(row.package_details ? row.package_details.game_details : null)
            setGameTransaction(row.package_details.game_details)
            setItemTransaction(row.package_item_tansactions)
            // setRow(row.booking_details)
          }}
        ></i>
      </span>
    )
  }

  function editFormatterItemTansaction(cell, row) {

    return (
      <span className="text-info" style={{ display: "flex", justifyContent: "center" }}>
        <i
          className="fas fa-info-circle fs-5 "
          title="View Transactions"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setviewItemTransactionModal(!viewItemTransactionModal)
            setSelectedRow(row)
            // setNestedPackageItemRow(row.package_details ? row.package_details.item_details : null)
            // setNestedPackageGameRow(row.package_details ? row.package_details.game_details : null)
            // setGameTransaction(row.package_details.game_details)
            setItemTransaction(row?.package_item_transactions)
            // console.log(row)
            // setRow(row.booking_details)
          }}
        ></i>
      </span>
    )
  }
  // console.log(itemTranscation)

  //load All Customers
  const loadAllCustomers = async (e) => {
   try {    
    setLoading(true)
    e&&setPageNumber(1)
    let url = process.env.REACT_APP_BASEURL + "customers/getall"
    let response = await postSubmitForm(url, {limit: 100,page: pageNumber})
    const sortedList = sortBy(response.data.customers, "name")
    if (response.status === 1) {
      setLoading(false);
      setTotalPage(response.data.totalPages)
      setAllCustomer(sortedList)
    } else {
      setLoading(false);
      showNotification(response.message, "Error")
    }
   } catch (error) {
    setLoading(false)
   }
  }

  //Packages
  const loadAllPackages = async customer => {
    Loader(true)
    let url = process.env.REACT_APP_BASEURL + "bookings/get_customer_packages"
    let response = await postSubmitForm(url, {
      mobile_no: customer?.mobile
    })
    if (response.status === 1) {
      Loader(false)
      setAllPackages(response.data.package_details)
      // console.log(response.data.package_details);
    } else {
      Loader(false)
      showNotification(response.message, "Error")
    }
  }

  // insert Customer
  const handleSubmit = async (e, v) => {
    try {
      const object = {
        name: v.name,
        mobile: v.mobile,
        email: v.email,
        qatar_id: v.qatar_id,
        passport: v.passport,
        date_of_birth: v.date_of_birth,
        address: v.address,
        is_wallet_eligible: is_wallet,
        customer_type: v.customer_type || "Regular",
      }
      // console.log(object);
      let url = process.env.REACT_APP_BASEURL + "customers/insert"
      postSubmitForm(url, object).then(res => {
        if (res.status === 1) {
          refContainer.current.reset()
          showNotification(res.message, "Success")
          setIs_Wallet(false)
          loadAllCustomers(null)
        } else {
          showNotification(res.message, "Error")
        }
      })
    } catch (error) {
      showNotification(error.message, "Error")
      console.log(error);
    }
  }

  //Update
  const handleValidUpdate = async (e, v) => {
    try {
      const object = {
        id: selectedRow._id,
        name: v.name,
        mobile: v.mobile,
        email: v.email,
        qatar_id: v.qatar_id,
        passport: v.passport,
        date_of_birth: v.date_of_birth,
        address: v.address,
        customer_type: v.customer_type,
        is_active: is_active,
        is_wallet_eligible: is_wallet_update,
      }
      let url = process.env.REACT_APP_BASEURL + "customers/update"
      //let url ="http://localhost:3002/customers/update"
      postSubmitForm(url, object).then(res => {
        if (res.status === 1) {
          showNotification(res.message, "Success")
          loadAllCustomers(null)
          setEditModal(!editModal)
        } else {
          showNotification(res.message, "Error")
        }
      })
    } catch (error) {
      showNotification(error.message, "Error")
    }
  }

  //Delete
  const handleValidDelete = async customer => {
    let url = process.env.REACT_APP_BASEURL + "customers/activate_deactivate"
    //let url = "http://localhost:3002/customers/delete"
    let response = await postSubmitForm(url, {
      id: customer._id,
      is_active: customer.is_active === true ? false : true
    })
    if (response.status === 1) {
      loadAllCustomers(null)
      showNotification(response.message, "Success")
    } else {
      showNotification(response.message, "Error")
    }
  }

  // console.log(is_wallet);
  // console.log(nestedPackageItemRow)

  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        const rowNumber = (+pageNumber - 1) * +pageSize + (+rowIndex + 1);
        return rowNumber;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "1%" }
      },
    },
    {
      dataField: "name",
      text: props.t("Name"),
      sort:true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },

    {
      dataField: "mobile",
      text: props.t("Mobile"),
      filter: textFilter({ 
        caseSensitive: false ,
        style: { height: "20px", 
      }}),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      dataField: "email",
      text: props.t("Email"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      dataField: "address",
      text: props.t("Address"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      dataField: "qatar_id",
      text: props.t("Qatar ID"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      dataField: "passport",
      text: props.t("Passport"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      dataField: "customerCreatedBy",
      text: props.t("Created By"),
      sort:true,
      formatter:(cell,row)=>{
        return row.customerCreatedBy?.employee_name
      },
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      dataField: "createdAt",
      text: props.t("Created On"),
      formatter:(cell,row)=>{
        return moment(row.createdAt).format("YYYY-MM-DD")
      },
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      dataField: "is_wallet_eligible",
      text: props.t("Wallet ?"),
      sort:true,
      formatter: (cell, row) => {
        return row.is_wallet_eligible ? "Yes" : "No"
      },

      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },

    {
      dataField: "customer_type",
      text: props.t(" Type"),
      filter: selectFilter({
        options: [
          { value: "Regular", label: "Regular" },
          { value: "VIP", label: "VIP" },
          { value: "Friend", label: "Friend" },
        ],
        placeholder: "ALL",
        onFilter: (value) => {
          setType( value )
        } 
      }),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },

    {
      dataField: "",
      text: props.t("Action"),
      formatter: editFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "6%" }
      },
    },
  ]

  const booking = [
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1
      },
      text: props.t("S.N"),
      headerStyle: (column, collIndex) => {
        return { width: "3%" }
      },
    },
    {
      dataField: "name",
      text: props.t("Name"),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
  ]

  const packages = [
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1
      },
      text: props.t("#"),
      headerStyle: (column, collIndex) => {
        return { width: "3%" }
      },
    },
    {
      dataField: "package_details.package_name",
      text: props.t("Package Name"),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "customer_details.name",
      text: props.t("Customer"),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "total_game_time",
      text: props.t("Total Minutes"),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "total_item_count.in_unit",
      text: props.t("Total Units"),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "total_item_count.in_sub_unit",
      text: props.t("Total Sub-Units"),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "expiry_date",
      text: props.t("Expiry Date"),
      formatter: (cell, row) => {
        return formatDate(row.expiry_date)
      },
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "",
      text: props.t("Action"),
      formatter: editFormatterPackage,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
  ]

  const PackageItemDetails = [

    {
      text: props.t("Image"),
      formatter: showPhotoFormatter,
      dataField: "image_url",
      //sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Name"),
      dataField: "name",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },

    {
      text: props.t("Available"),
      dataField: "package_item_remaining.in_unit",
      sort: false,
      formatter: (cell, row) => {
        // return row.package_item_remaining.in_unit + " " + row.unit + " " + row.package_item_remaining.in_sub_unit + " " + row.sub_unit
        return (<>
        {row.package_item_remaining?.in_unit +
          "  " +
          row.unit +
          " " +
          (row.sub_unit && row.sub_unit != "undefined"
            ? row.package_item_remaining.in_sub_unit + " " + row.sub_unit
            : "")}
        </>)
      },
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },

    {
      dataField: "",
      text: props.t("Transactions"),
      formatter: editFormatterItemTansaction,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
  ]

  const PackageGameDetails = [
    {
      text: props.t("Image"),
      formatter: showPhotoFormatter,
      dataField: "image",
      //sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Name"),
      dataField: "name",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Remaining Minutes"),
      dataField: "package_game_remaining",
      sort: false,
      style: (cell, row) => {
        return {
          textAlign: "center"
        };
      },
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      dataField: "",
      text: props.t("Transactions"),
      formatter: (cell, row) => {
        return (
          <span className="text-info" style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-info-circle fs-5"
              title="View Transactions"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setviewGameTransactionModal(!viewGameTransactionModal)
                setSelectedRow(row)
                setGameTransaction(row?.package_game_transactions)
                // console.log(row);
              }}
            ></i>
          </span>
        )
      },
      headerStyle: (colum, colIndex) => {
        return { width: '2%' }
      }
    }
  ]


  const ItemTransactions = [

    {
      dataField: "transaction_type",
      text: props.t("Transaction Type"),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "transaction_date",
      text: props.t("Transaction Date"),
      formatter: (cell, row) => {
        return formatDate(row.transaction_date)
      },
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Transaction Quantity"),
      dataField: "transaction_quantity",
      sort: false,
      formatter: (cell, row) => {
        return (
          <>
            {/* {row.transaction_quantity.in_unit + "  " + selectedRow.unit + " " + row.transaction_quantity.in_sub_unit + " " + selectedRow.sub_unit} */}
            {row.transaction_quantity?.in_unit +
                "  " +
                selectedRow.unit +
                " " +
                (selectedRow.sub_unit && selectedRow.sub_unit != "undefined"
                  ? row.transaction_quantity.in_sub_unit + " " + selectedRow.sub_unit
                  : "")}
          </>
        );
      },
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      dataField: "transaction_remarks",
      text: props.t("Remarks"),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
  ]

  const GameTransactions = [

    {
      dataField: "transaction_type",
      text: props.t("Transaction Type"),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "transaction_date",
      text: props.t("Transaction Date"),
      formatter: (cell, row) => {
        return formatDate(row.transaction_date)
      },
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "duration",
      text: props.t("Duration"),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },

    {
      dataField: "transaction_remarks",
      text: props.t("Remarks"),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
  ]


  const rowStyle = row => {
    if (row.is_active === false) {
      return { backgroundColor: "#ffcdd2" }
    }
    return {}
  }

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  // console.log("Wallet accee",is_wallet_update);

  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Customer")} 
            breadcrumbItem1={props.t("Add Customer")}
            alternateTitle={props.t("Add Customer")} />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody

                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  <AvForm onValidSubmit={handleSubmit} ref={refContainer}>
                    <Row>
                      <Col lg={6}>
                        <AvField
                          label={props.t("Name *")}
                          className="form-control"
                          type="text"
                          name="name"
                          placeholder={props.t("Enter Name")}
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Name cannot be empty")}
                        />

                      </Col>
                      <Col lg={6}>
                        <AvField
                          onWheel={preventValueChangeOnScroll}
                          label={props.t("Mobile *")}
                          className="form-control"
                          type="number"
                          name="mobile"
                          placeholder={props.t("Enter Phone Number")}
                          validate={{
                            required: { value: true },
                            maxLength: {
                              value: 13,
                              errorMessage: props.t(
                                "Maximum 13 Digits are allowed."
                              ),
                            },
                          }}
                          errorMessage={props.t("Phone number cannot be empty")}
                        />

                      </Col>

                    </Row>

                    <Row>
                      <Col lg={6}>

                        <AvField
                          label={props.t("Date Of Birth")}
                          className="form-control"
                          type="select"
                          tag={Flatpickr}
                          options={{
                            onDayCreate: (dObj, dStr, fp, dayElem) => {
                              dayElem.addEventListener("mousedown", e => {
                                e.preventDefault() // Prevent default click behavior
                              })
                            },
                          }}
                          name="date_of_birth"
                          placeholder={props.t("Select D.O.B")}
                        // validate={{ required: { value: true } }}
                        // errorMessage={props.t("D.O.B cannot be empty")}
                        />

                      </Col>

                      <Col lg={6}>

                        <AvField
                          label={props.t("Email")}
                          className="form-control"
                          type="email"
                          name="email"
                          placeholder={props.t("Enter Email")}
                        // validate={{ required: { value: true } }}
                        // errorMessage={props.t("Email cannot be empty")}
                        />

                      </Col>
                    </Row>
                    <Row>

                      <Col lg={6}>
                        <AvField
                          onWheel={preventValueChangeOnScroll}
                          label={props.t("Qatar ID")}
                          className="form-control"
                          type="number"
                          name="qatar_id"
                          onChange={(e) => {
                            setQatarID(e.target.value)
                          }}
                          placeholder={props.t("Enter Qatar ID")}
                        // validate={{
                        //   required: { value: passport ? false : true },
                        //   maxLength: {
                        //     value: 10,
                        //     errorMessage: props.t(
                        //       "Maximum 10 characters allowed."
                        //     ),
                        //   },
                        // }}
                        // errorMessage={props.t(
                        //   "Both Qatar ID and Passport Number cannot be empty."
                        // )}
                        />

                      </Col>
                      <Col lg={6}>

                        <AvField
                          label={props.t("Passport")}
                          className="form-control"
                          type="text"
                          name="passport"
                          onChange={(e) => {
                            setPassport(e.target.value)
                          }}
                          placeholder={props.t("Enter Passport Number")}
                        // validate={{ required: { value: qatarID ? false : true } }}
                        // errorMessage={props.t(
                        //   "Both Qatar ID and Passport Number cannot be empty."
                        // )}
                        />

                      </Col>
                    </Row>
                    <Row>


                      {/* <Col lg={6}>
                    
                     <FormGroup check className="mt-4" style={{display:"flex", justifyContent:"center"}}>
                          <Label check >
                            <Input

                              type="checkbox"
                              checked={isVIPCustomer}
                              onChange={(e) => setIsVIPCustomer(e.target.checked)}
                            />
                          V.I.P Customer
                          </Label>
                          
                        </FormGroup>
            
                </Col> */}


                      <Col lg={6}>
                        <AvField
                          label={props.t("Address")}
                          className="form-control"
                          type="textarea"
                          name="address"
                          placeholder={props.t("Enter Address")}
                        />

                      </Col>

                      <Col lg={6}>
                        <Row>
                          <Col lg={6}>
                            <AvField
                              label={props.t("Customer Type")}
                              className="form-control"
                              type="select"
                              name="customer_type"
                              defaultValue="Regular"
                              placeholder={props.t("Select Customer Type")}
                            // validate={{ required: { value: true } }}
                            // errorMessage={props.t("Customer Type cannot be empty")}
                            >
                              <option value="">{props.t("--Select Type--")}</option>
                              <option value="Regular">{props.t("Regular")}</option>
                              <option value="Friend">{props.t("Friend")}</option>
                              <option value="VIP">{props.t("VIP")}</option>
                            </AvField>
                          </Col>
                          <Col lg={6}>
                            <FormGroup check className="" style={{ display: "flex", justifyContent: "center" }}>
                              {/* <Label check  >
                                <Input style={{ border: "1px solid black" }}
                                  type="checkbox"
                                  checked={is_wallet}
                                  onChange={(e) => setIs_Wallet(e.target.checked)}
                                />
                               
                                <Badge  className={is_wallet===false?`${styles.glowBadge} badge-soft-danger`:`$ badge-soft-success` }>Wallet Access</Badge>
                             
                              </Label> */}
                              <AvRadioGroup inline name="radioExample2" defaultValue="false"
                                label={<Badge style={{ fontSize: "12px" }} className={is_wallet === false ? `${styles.glowBadge} badge-soft-danger` : `$ badge-soft-success`}>{props.t("Wallet Access")}</Badge>}
                                required>
                                <AvRadio label="Yes" value="true" onChange={(e) => setIs_Wallet(true)} />
                                <AvRadio label="No" value="false" onChange={(e) => setIs_Wallet(false)} />
                              </AvRadioGroup>

                            </FormGroup>
                            {/* <CheckBox 
                            style={{marginTop:"100px"}}
                            label="Wallet Required"
                            checked={is_wallet} 
                            onChange={(e)=> setIs_Wallet(e.target.checked)}/> */}
                          </Col>

                        </Row>

                      </Col>
                    </Row>

                    <hr />
                    <Row>


                      <Button
                        type="submit"
                        style={{
                          position: "relative",
                          overflow: "hidden",
                          backgroundColor: "#fdd005",
                          border: "none",
                          color: "black",
                          fontSize: "14px",
                          fontWeight: "500",
                          width: "130px",
                          marginTop: "1.6rem",
                          margin: "auto",
                          boxShadow:
                            "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                          transition: "transform 0.3s ease",
                        }}
                        onMouseEnter={e => {
                          e.target.style.transform = "scale(1.05)"
                        }}
                        onMouseLeave={e => {
                          e.target.style.transform = "scale(1)"
                        }}
                      >
                        {props.t("Add Customer")}
                        <div
                          style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            right: "0",
                            bottom: "0",
                            background:
                              "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                            pointerEvents: "none",
                          }}
                        ></div>
                      </Button>


                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  <CardTitle>
                    <Row>
                      <Col lg={6}>
                        {props.t("All Customers")}
                      </Col>

                      <Col lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                          style={{ width: "115px", height: "35px", background: "#F93A5C", border: "none" }}

                          type="submit"
                          className="btn btn-sm btn-secondary"
                          onClick={handleDownload}
                          disabled={!allCustomer}
                        >
                          <i className="mdi mdi-file-export" /> &nbsp;
                          {props.t("Export CSV")}
                        </Button>
                      </Col>
                    </Row>
                  </CardTitle>
                  <ToolkitProvider
                    keyField="_id"
                    columns={columns}
                    data={allCustomer}
                    search
                  >
                    {propst => (
                      <div>
                        <AvForm>
                          <Row>
                            <Col
                              lg={4}
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                              }}
                            >
                              <SearchBar
                                {...propst.searchProps}
                                style={{ width: "340px" }}
                              />
                            </Col>
                          </Row>
                        </AvForm>
                        <BootstrapTable
                          {...propst.baseProps}
                          // striped
                          noDataIndication={loading?<TableLoader/>:props.t("No data to display.")}
                          filter={filterFactory()}
                          condensed
                          rowStyle={rowStyle}
                          // pagination={paginationFactory(options)}
                        />
                      <Pagination pageNumber={pageNumber} setPageNumber={setPageNumber} totalPage={totalPage}/>
                     </div>
                    )}
                  </ToolkitProvider>

                  {/* update modal */}
                  <Modal
                    size="lg"
                    isOpen={editModal}
                    toggle={() => setEditModal(!editModal)}
                  >
                    <ModalHeader toggle={() => setEditModal(!editModal)}>
                      {props.t("Update Customer Details - ")}
                      {selectedRow && selectedRow.name}
                    </ModalHeader>
                    <ModalBody>
                      {selectedRow && (
                        <>
                          <Row>
                            <Col xlg={12}>
                              <Card>
                                <CardBody>
                                  <AvForm onValidSubmit={handleValidUpdate}>
                                    <Row>
                                      <Col lg={6}>
                                        <AvField
                                          label={props.t("Name")}
                                          className="form-control"
                                          type="text"
                                          value={selectedRow.name}
                                          name="name"
                                          placeholder={props.t("Enter Name")}

                                        />

                                      </Col>
                                      <Col lg={6}>
                                        <AvField
                                          onWheel={preventValueChangeOnScroll}
                                          label={props.t("Mobile")}
                                          className="form-control"
                                          type="number"
                                          name="mobile"
                                          validate={{
                                            required: { value: true },
                                            maxLength: {
                                              value: 13,
                                              errorMessage: props.t(
                                                "Maximum 13 Digits are allowed."
                                              ),
                                            },
                                          }}
                                          errorMessage={props.t("Phone number cannot be empty")}
                                          value={selectedRow.mobile}


                                        />

                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg={6}>

                                        <AvField
                                          label={props.t("Date Of Birth")}
                                          className="form-control"
                                          type="select"
                                          name="date_of_birth"
                                          tag={Flatpickr}
                                          options={{
                                            onDayCreate: (dObj, dStr, fp, dayElem) => {
                                              dayElem.addEventListener("mousedown", e => {
                                                e.preventDefault() // Prevent default click behavior
                                              })
                                            },
                                          }}
                                          value={formatDate(selectedRow.date_of_birth)}
                                          placeholder={props.t("Enter D.O.B")}

                                        />

                                      </Col>


                                      <Col lg={6}>

                                        <AvField
                                          label={props.t("Email")}
                                          className="form-control"
                                          type="text"
                                          name="email"
                                          value={selectedRow.email}
                                          placeholder={props.t("Enter Email")}

                                        />

                                      </Col>
                                    </Row>
                                    <Row>

                                      <Col lg={6}>
                                        <AvField
                                          onWheel={preventValueChangeOnScroll}
                                          label={props.t("Qatar ID")}
                                          className="form-control"
                                          type="number"
                                          name="qatar_id"
                                          value={selectedRow.qatar_id}
                                          placeholder={props.t("Enter Qatar ID")}

                                        />

                                      </Col>
                                      <Col lg={6}>

                                        <AvField
                                          label={props.t("Passport")}
                                          className="form-control"
                                          type="text"
                                          name="passport"
                                          value={selectedRow.passport}
                                          placeholder={props.t("Enter Passport Number")}

                                        />

                                      </Col>
                                    </Row>
                                    <Row>

                                      <Col lg={6}>
                                        <AvField
                                          label={props.t("Address")}
                                          className="form-control"
                                          type="textarea"
                                          name="address"
                                          value={selectedRow.address}
                                          placeholder={props.t("Enter Address")}

                                        />

                                      </Col>

                                      <Col lg={6}>
                                        <AvField
                                          label={props.t("Customer Type")}
                                          className="form-control"
                                          type="select"
                                          name="customer_type"
                                          placeholder={props.t("Select Customer Type")}
                                          value={selectedRow.customer_type}
                                        >
                                          <option value="">{props.t("--Select Type--")}</option>
                                          <option value="Regular">{props.t("Regular")}</option>
                                          <option value="Friend">{props.t("Friend")}</option>
                                          <option value="VIP">{props.t("VIP")}</option>
                                        </AvField>

                                      </Col>

                                    </Row>

                                    <Row>
                                      {/* <Col lg={6}>
                                        <AvCheckboxGroup name="groupcheck" inline  >

                                          <AvCheckbox
                                            style={{ border: "1px solid black" }}
                                            name="is_active"
                                            label="Active"
                                            value="is_active"
                                            checked={is_active}
                                            //checked={allDesignation.is_driving_license_required}
                                            onChange={e =>
                                              setis_active(e.target.checked)
                                            }
                                          />

                                        </AvCheckboxGroup> 
                                         
                                      </Col> */}

                                      {/* <Col lg={6}>
                                        <AvCheckboxGroup name="groupcheck" inline  >

                                          <AvCheckbox
                                            style={{ border: "1px solid black" }}
                                            name="is_wallet_eligible"
                                            label="Wallet Required"
                                            value="is_wallet_eligible"
                                            checked={is_wallet_update}
                                            onChange={e =>
                                              setIs_wallet_update(e.target.checked)
                                            }
                                          />

                                        </AvCheckboxGroup>
                                      </Col> */}

                                      <Col lg={6}>
                                      <AvRadioGroup 
                                        inline 
                                        name="is_wallet" 
                                        defaultValue={`${is_wallet_update}`}
                                        label={<Badge style={{ fontSize: "12px" }} className={is_wallet_update === false ? `${styles.glowBadge} badge-soft-danger` : `$ badge-soft-success`}>Wallet Access</Badge>}
                                        required>
                                        <AvRadio label="Yes" value="true"  onChange={() => setIs_wallet_update(true) } />
                                        <AvRadio label="No" value="false"   onChange={() => setIs_wallet_update(false)} />
                                      </AvRadioGroup>

                                  

                                      </Col>
                                    </Row>

                                    <hr />
                                    <Row>

                                      <Col lg={12} style={{ display: "flex", justifyContent: "center" }}>
                                        <Button
                                          type="submit"
                                          style={{
                                            position: "relative",
                                            overflow: "hidden",
                                            backgroundColor: "#fdd005",
                                            border: "none",
                                            color: "black",
                                            fontSize: "15px",
                                            fontWeight: "500",
                                            width: "100px",
                                            marginTop: "1.6rem",
                                            boxShadow:
                                              "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                            transition: "transform 0.3s ease",
                                          }}
                                          onMouseEnter={e => {
                                            e.target.style.transform = "scale(1.05)"
                                          }}
                                          onMouseLeave={e => {
                                            e.target.style.transform = "scale(1)"
                                          }}
                                        >
                                          {props.t("Update")}
                                          <div
                                            style={{
                                              position: "absolute",
                                              top: "0",
                                              left: "0",
                                              right: "0",
                                              bottom: "0",
                                              background:
                                                "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                              pointerEvents: "none",
                                            }}
                                          ></div>
                                        </Button>
                                      </Col>

                                    </Row>
                                  </AvForm>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>

                  {/* View Bookings */}

                  <Modal
                    size="lg"
                    isOpen={viewModal}
                    toggle={() => setViewModal(!viewModal)}
                  >
                    <ModalHeader toggle={() => setViewModal(!viewModal)}>
                      {props.t("Booking Details - ")}
                      {selectedRow && selectedRow.name}
                    </ModalHeader>
                    <ModalBody>
                      {Row2 && (
                        <>
                          <Row>
                            <Col xlg={12}>
                              <Card>
                                <CardBody>
                                  <ToolkitProvider
                                    keyField="_id"
                                    columns={booking}
                                    data={Row2 || []}
                                    search
                                  >
                                    {propst => (
                                      <div>
                                        <AvForm>
                                          <Row>
                                            <Col
                                              lg={4}
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                              }}
                                            >
                                              <SearchBar
                                                {...propst.searchProps}
                                                style={{ width: "340px" }}
                                              />
                                            </Col>
                                          </Row>
                                        </AvForm>
                                        <BootstrapTable
                                          {...propst.baseProps}
                                          noDataIndication={props.t("No data to display .")}
                                          striped
                                          hover
                                          condensed
                                        />
                                      </div>
                                    )}
                                  </ToolkitProvider>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>

                  {/* view Item Transactions */}
                  <Modal
                    size="lg"
                    isOpen={viewItemTransactionModal}
                    toggle={() => setviewItemTransactionModal(!viewItemTransactionModal)}
                  >
                    <ModalHeader toggle={() => setviewItemTransactionModal(!viewItemTransactionModal)}>
                      {props.t("Item Transactions - ")} {selectedRow?.name}
                    </ModalHeader>
                    <ModalBody>
                      {Row2 && (
                        <>
                          <Row>
                            <Col xlg={12}>
                              <Card>
                                <CardBody>
                                  <ToolkitProvider
                                    keyField="_id"
                                    columns={ItemTransactions}
                                    data={itemTranscation}
                                    search
                                  >
                                    {propst => (
                                      <div>
                                        <AvForm>
                                          <Row>
                                            <Col
                                              lg={4}
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                              }}
                                            >
                                              {/* <SearchBar
                                                {...propst.searchProps}
                                                style={{ width: "340px" }}
                                              /> */}
                                            </Col>
                                          </Row>
                                        </AvForm>
                                        <BootstrapTable
                                          {...propst.baseProps}
                                          noDataIndication={props.t("No data to display .")}
                                          striped
                                          hover
                                          condensed
                                        />
                                      </div>
                                    )}
                                  </ToolkitProvider>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>

                  {/* view Game Transactions */}
                  <Modal
                    size="lg"
                    isOpen={viewGameTransactionModal}
                    toggle={() => setviewGameTransactionModal(!viewGameTransactionModal)}
                  >
                    <ModalHeader toggle={() => setviewGameTransactionModal(!viewGameTransactionModal)}>
                      {props.t("Game Transactions - ")} {selectedRow?.name}
                    </ModalHeader>
                    <ModalBody>
                      {Row2 && (
                        <>
                          <Row>
                            <Col xlg={12}>
                              <Card>
                                <CardBody>
                                  <ToolkitProvider
                                    keyField="_id"
                                    columns={GameTransactions}
                                    data={gameTranscation}
                                    search
                                  >
                                    {propst => (
                                      <div>
                                        <AvForm>
                                          <Row>
                                            <Col
                                              lg={4}
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                              }}
                                            >
                                              {/* <SearchBar
                                                {...propst.searchProps}
                                                style={{ width: "340px" }}
                                              /> */}
                                            </Col>
                                          </Row>
                                        </AvForm>
                                        <BootstrapTable
                                          {...propst.baseProps}
                                          noDataIndication={props.t("No data to display .")}
                                          striped
                                          hover
                                          condensed
                                        />
                                      </div>
                                    )}
                                  </ToolkitProvider>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>


                  {/* View Packages */}

                  <Modal
                    size="lg"
                    isOpen={viewPackageModal}
                    toggle={() => { setViewPackageModal(!viewPackageModal); setPackageDetails() }}
                  >
                    <ModalHeader toggle={() => setViewPackageModal(!viewPackageModal)}>
                      {props.t("Package Details - ")}
                      {packageRow && packageRow.name}
                    </ModalHeader>
                    <ModalBody>
                      {Row2 && (
                        <>
                          <Row>
                            <Col xlg={12}>
                              <Card>
                                <CardBody>
                                  <ToolkitProvider
                                    keyField="_id"
                                    columns={packages}
                                    data={allPackages || []}
                                    search
                                  >
                                    {propst => (
                                      <div>
                                        <AvForm>
                                          <Row>
                                            <Col
                                              lg={4}
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                              }}
                                            >
                                              <SearchBar
                                                {...propst.searchProps}
                                                style={{ width: "340px" }}
                                              />
                                            </Col>
                                          </Row>
                                        </AvForm>
                                        <BootstrapTable
                                          {...propst.baseProps}
                                          noDataIndication={props.t("No data to display .")}
                                          striped
                                          hover
                                          condensed
                                        />
                                      </div>
                                    )}
                                  </ToolkitProvider>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>

                  {/* View Package Details */}
                  <Modal
                    size="xl"
                    isOpen={viewNestedPackageModal}
                    toggle={() => { setViewNestedPackageModal(!viewNestedPackageModal); setPackageDetails() }}
                  >
                    <ModalHeader toggle={() => setViewNestedPackageModal(!viewNestedPackageModal)}>
                      {props.t("Details  ")}
                      {selectedRow && selectedRow.package_details?.package_name}
                    </ModalHeader>
                    <ModalBody>
                      {Row2 && (
                        <>
                          
                          <Row>
                            <Col md="3">
                              <Nav pills className="flex-column">
                                <NavItem>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      "mb-2": true,
                                      active: verticalActiveTab === "1",
                                    })}
                                    onClick={() => {
                                      toggleVertical("1");
                                    }}
                                  >

                                    {props.t("Games")}
                                  </NavLink>
                                </NavItem>



                                <NavItem>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      "mb-2": true,
                                      active: verticalActiveTab === "2",
                                    })}
                                    onClick={() => {
                                      toggleVertical("2");
                                    }}
                                  >
                                    {props.t("Items")}
                                  </NavLink>
                                </NavItem>



                              </Nav>
                            </Col>
                            <Col md="9">
                              <TabContent
                                activeTab={verticalActiveTab}
                                className="text-muted mt-4 mt-md-0"
                              >
                                <TabPane tabId="1">
                                  <ToolkitProvider
                                    keyField="_id"
                                    columns={PackageGameDetails}
                                    data={nestedPackageGameRow && nestedPackageGameRow}
                                    // data={[]}
                                    search
                                  >
                                    {propst => (
                                      <div>
                                        <AvForm>
                                          <Row>
                                            <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                                              <SearchBar {...propst.searchProps} style={{ width: "340px" }} />
                                            </Col>
                                          </Row>
                                        </AvForm>
                                        <BootstrapTable

                                          {...propst.baseProps}
                                          noDataIndication={props.t("No data to display.")}
                                          striped
                                          hover
                                          condensed
                                        />
                                      </div>
                                    )}
                                  </ToolkitProvider>

                                </TabPane>
                                <TabPane tabId="2">
                                  {/* Game Details */}
                                  <ToolkitProvider
                                    keyField="_id"
                                    columns={PackageItemDetails}
                                    data={nestedPackageItemRow && nestedPackageItemRow}
                                    // data={[]}
                                    search
                                  >
                                    {propst => (
                                      <div>
                                        <AvForm>
                                          <Row>
                                            <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                                              <SearchBar {...propst.searchProps} style={{ width: "340px" }} />
                                            </Col>
                                          </Row>
                                        </AvForm>
                                        <BootstrapTable

                                          {...propst.baseProps}
                                          noDataIndication={props.t("No data to display.")}
                                          striped
                                          hover
                                          condensed
                                        />
                                      </div>
                                    )}
                                  </ToolkitProvider>

                                </TabPane>

                               


                              </TabContent>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(connect(null, {})(withTranslation()(AddCustomer)))
