import React, { useCallback, useEffect, useState } from 'react'
import { Button, Card, CardBody, CardTitle, Col, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import withRouter from '../../components/Common/withRouter'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import cellEditFactory from "react-bootstrap-table2-editor"
import Select from 'react-select'
import showNotification, { showWarning } from '../../helpers/show_notificaton'
import { postSubmitForm } from '../../helpers/backend_helper'
import preventValueChangeOnScroll from '../../helpers/PreventValueOnScroll'
import preloader from '../../helpers/Loader'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import { useRef } from 'react'
import { debounce } from 'lodash'




const DsbdBookModal = (props) => {
    const [allCustomer, setAllCustomer] = useState([])
    const [updatedgameData, setUpdatedGameData] = useState([])
    const [selectedCustomer, setSelectedCustomer] = useState({})
    const [diabeledTracker, setDiabeledTracker] = useState(true)
    const [branches, setBranches] = useState([])
    const [addCustomerModal, setAddCustomerModal] = useState(false)
    const refContainer = useRef(null)
   
    const loadAllBranches = async () => {
        let url = process.env.REACT_APP_BASEURL + "branches/getall"
        let response = await postSubmitForm(url, "")
        if (response && response.status === 1) {
          setBranches(response.data)
        } else {
          console.log("Error")
        }
      }
    
  const getPriceForCustomerType = (game, customerType) => {
    switch (customerType) {
      case "Friend":
        return game.price_for_friend;
      case "Regular":
        return game.price_for_regular;
      case "VIP":
        return 0; // VIPs get a special price
      default:
        return 0;
    }
  };



    useEffect(() => {
        // loadAllCustomers();
        loadAllBranches()
        let gameData = [props.gameData] || [];
      let currTime = new Date()
      const updatedGameData = gameData.map((game, index) => {
        let price = 0;

        switch (selectedCustomer?.customer_type) {
          case "Friend":
            price = game.game.price_for_friend;
            break;
          case "Regular":
            price = game.game.price_for_regular;
            break;
          case "VIP":
            price = 0;
            break;
          default:
            break;
        }  
        const subTotal = (price / 60) * 30;

        if (index === 0) {
            return {
              _id:game._id,
              capacity:game.capacity,
              is_active:game.is_active,
              is_occupied:game.is_occupied,
              table_image_url:game.table_image_url,
              table_number:game.table_number,
              table_type: game.table_type,
              game: {
                ...game.game,
                duration:30,
                sub_total: +subTotal.toFixed(2),                table_start_time: new Date(),
                table_end_time: new Date(currTime.getTime() + 30 * 60000)
              }
            };
          }
          return game;
        });
      
        setUpdatedGameData(updatedGameData);
      
      }, [selectedCustomer]);
    
      const handleValidSubmit = async () => {
        preloader(true)
         let url = process.env.REACT_APP_BASEURL + "bookings/insert"
        const payload = {
            check_in:updatedgameData[0]?.game.table_start_time,
            booking_status:"In Process",
          start_time:updatedgameData[0]?.game.table_start_time,
          end_time:updatedgameData[0]?.game.table_end_time,
          game_total_price:updatedgameData[0]?.game.sub_total,
          total_price:updatedgameData[0]?.game.sub_total,
          package_details: [],
          item_details: [],
          game_details: updatedgameData,
          customer_details: {
            _id: selectedCustomer._id,
            name: selectedCustomer.name,
            mobile: selectedCustomer.mobile,
            customer_type: selectedCustomer.customer_type,
            is_wallet_eligible: selectedCustomer.is_wallet_eligible,
            wallet: selectedCustomer?.wallet,
            address: selectedCustomer.address,
          },
          branch_details: {
            branch_id: branches[0]?._id,
            is_active: branches[0]?.is_active,
            name: branches[0]?.name,
            address: branches[0]?.address,
            email: branches[0]?.email,
            mobile: branches[0]?.mobile,
          },
        }
        // console.log(payload, "payload")
        // return
        postSubmitForm(url, payload)
          .then(response => {
            if (response.status === 1 && response.data) {
                preloader(false)
              showNotification(response.message,"Success")
              setSelectedCustomer({})
              setDiabeledTracker(false)
              props.onClose();
              props.reFresh()
            } else {
             preloader(false)
              showNotification(response.message, "Error")
            }
          })
          .catch(err => {
            preloader(false)
            showNotification(err,"Error")
          })    
    }



  // const loadAllCustomers = async () => {
  //   let url = process.env.REACT_APP_BASEURL + "customers/getall"
  //   let response = await postSubmitForm(url, "")

  //   if (response.status === 1) {
  //     response.data.map(customer => {
  //       ;(customer.label = `${customer.name} [${customer.mobile}] [${customer.customer_type}]`),
  //         (customer.value = customer._id)
  //     })
  //     const active = response.data.filter(cust => cust.is_active === true)
  //     setAllCustomer(active)
  //   } else {
  //     showNotification(response.message, "Error")
  //   }
  // }

  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearch = useCallback(
    
    debounce(async (searchValue) => {
      // console.log("called");
      // Only search if search term is at least 3 characters
      if (searchValue.length < 3) {
        // setAllCustomer([]);
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        let url = `${process.env.REACT_APP_BASEURL}customers/getall_for_booking`;
        let response = await postSubmitForm(url, { search_query: searchValue });

        if (response.status === 1) {
          const formattedCustomers = response.data.map(customer => ({
            ...customer,
            label: `${customer.name} [${customer.mobile}] [${customer.customer_type}]`,
            value: customer._id
          }));

          setAllCustomer(formattedCustomers);
        } else {
          // showNotification(response.message, "Error");
          // setAllCustomer([]);
        }
      } catch (error) {
        showNotification("Error searching customers", "Error");
        setAllCustomer([]);
      } finally {
        setLoading(false);
      }
    }, 800), // 800ms debounce delay
    []
  );

  useEffect(() => {
    debouncedSearch(searchTerm);
  }, [searchTerm, debouncedSearch]);
  // insert Customer
  const handleAddCustomer = async (e, v) => {
    try {
      const object = {
        name: v.name,
        mobile: v.mobile,
        customer_type: v.customer_type || "Regular",
      }
      // console.log(object);
      let url = process.env.REACT_APP_BASEURL + "customers/insert"
      postSubmitForm(url, object).then(res => {
        if (res.status === 1) {
          refContainer.current.reset()
          showNotification(res.message, "Success")
          setAddCustomerModal(false)
          setAllCustomer([])
          // loadAllCustomers()
        } else {
          showNotification(res.message, "Error")
        }
      })
    } catch (error) {
      showNotification(error.message, "Error")
      console.log(error)
    }
  }

    function showPhotoFormatter(cell, row) {
        return (
            <>
                <img
                    src={row.game.image_url || row.game.image}
                    alt={row.game.name}
                    className="avatar-md rounded-circle img-thumbnail"
                />
                <br />
                {row.table_number} {`(${row.game?.name})`}
                <span
                    style={{ cursor: "pointer" }}
                    className="font-size-12 badge-soft-info badge badge-success badge-pill"
                ></span>
            </>
        )
    }

    const handleStartTime = async (e, rowIndex, row, editorProps, value) => {
        const { value: selectedValue } = e.target
        if (!selectedValue || isNaN(Date.parse(selectedValue))) {
          return
        }
        const durationInMinutes = row.game.duration
        const NewstartTime = new Date(selectedValue)
        const NewendTime = new Date(
          NewstartTime.getTime() + durationInMinutes * 60000
        )    
        let payload = {
          start_time: NewstartTime,
          end_time: NewendTime,
          game_id: row.game.game_id,
        }
        let PieUrl =
          process.env.REACT_APP_BASEURL + "bookings/table_bookings_by_date"
        let res = await postSubmitForm(PieUrl, payload)
        if (
          res &&
          res.status === 1 &&
          res.data.some(item => item.table_number === row.table_number)
        ) {
          const updatedTableData = { ...row }
          const rowData = updatedTableData
          editorProps.onUpdate(new Date(selectedValue))
          rowData.game.table_start_time = new Date(selectedValue)
          rowData.game.table_end_time = NewendTime
        
        } else {
          showWarning("Table is busy", "warning")
        }
    }
   
    const  handleDurationChange  = async (e, rowIndex, row, editorProps)=>{
        const { value } = e.target
        const requestDura = (value);      
        const endTime = new Date(row.game.table_start_time);
        const updatedET = new Date(endTime.getTime() + requestDura * 60000);
    
        let payload = {
            start_time: row.game.table_start_time,
            end_time: updatedET,
            game_id: row.game.game_id,
        };
    
        let PieUrl =
          process.env.REACT_APP_BASEURL + "bookings/table_bookings_by_date"
        let res = await postSubmitForm(PieUrl, payload)
        if ( res && res.status === 1 &&
          res.data.some(item => item.table_number === row.table_number)
        ) {
        const duration = +value 
        editorProps.onUpdate(duration)
    
        const NewStartTime = new Date(row?.game.table_start_time)
        const durationInMinutes = row?.game.duration
        const NewendTime = new Date(
          NewStartTime.getTime() + durationInMinutes * 60000
        )
    
    let updated =  updatedgameData.map(item => {
          if (item._id === row._id) {
            if (row.game.name.includes("VIP")) {
              if (selectedCustomer?.customer_type === "VIP") {
                item.game.sub_total = 0;
              } else {
                if (+value > 60) {
                  let sixtyMin = 60
                  let rem = +value - sixtyMin
                  item.game.sub_total = (150 / 60) * sixtyMin + (100 / 60) * rem
                  item.game.sub_total = +item.game.sub_total.toFixed(2)
                } else {
                  item.game.sub_total = (150 / 60) * +value
                  item.game.sub_total = +item.game.sub_total.toFixed(2)
                }
              }
            } else {
              let price = getPriceForCustomerType(row.game, selectedCustomer?.customer_type)
              item.game.sub_total = (price / 60) * +value
              item.game.sub_total = +item.game.sub_total.toFixed(2)
            }
            item.game.duration = duration
            item.game.table_end_time = NewendTime.toISOString()
          }
          return item
        })
        // console.log(GamesInTable,"GTTT");
        // const maxEndTime = findMaxEndTime(GamesInTable);
        // if (maxEndTime) {
        //   const newFormattedTime = moment(maxEndTime).format("YYYY-MM-DD HH:mm:ss");
        //   setBookingEndTime(newFormattedTime);
        // } else {
        //   setBookingEndTime(prev=>prev)
        //   console.log("No End times available in game tables");
        // }
        setUpdatedGameData(updated)
        }else{
          showWarning("Table is busy","warning")
        }
    
       
    }
    
    

    const GameColumn = [
        {
            dataField: "game._id",
            hidden: true,
        },
        {
            dataField: "game._id",
            formatter: (cell, row, rowIndex) => {
                // const rowNumber = (+pageNumber - 1) * +pageSize + (+rowIndex + 1);
                const rowNumber = rowIndex + 1
                return rowNumber
            },
            text: props.t("S.N"),
            headerStyle: (colum, colIndex) => {
                return { width: "1%" }
            },
            editable: false
        },
        {
            text: props.t("Game"),
            formatter: showPhotoFormatter,
            dataField: "game.image",
            //sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
            editable: false
        },
        {
            dataField: "game.table_start_time",
            text: "Start Time",
            formatter: (cell, row) => {
              return moment(row.game?.table_start_time).format("HH:mm")
            },
            headerStyle: (colum, colIndex) => {
              return { width: "7%" }
            },
            editorRenderer: (
              editorProps,
              value,
              row,
              column,
              rowIndex,
              columnIndex
            ) => {
              if (row.game?.table_start_time) {
                return (
                  <input
                    style={{
                      width: "100px",
                      border: "1px solid lightGray",
                      padding: "5px",
                      borderRadius: "5px",
                      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                    }}
                    type="datetime-local"
                    defaultValue={value}
                    onBlur={e => {
                      handleStartTime(e, rowIndex, row, editorProps, value)
                    }}
                    onFocus={e => e.target.select()}
                    autoFocus
                  />
                )
              } else {
                return <div style={{ color: "red" }}>Not Available</div>
              }
            },
            validator: (newValue, row, column) => {
                //console.log(newValue)
                if (Object.keys(selectedCustomer)?.length === 0 ) {
                    showWarning("Please select customer first !","warning")
                  return {
                    valid: false,
                  }
                }
            
                return true
              },
            editable: true,
          },
      
          {
            dataField: "game.duration",
            text: "Duration",
            formatter: (cell, row) => {
              return (
                <p
                  style={{
                    color: row.game.duration === 0 ? "#E57373" : "",
                    fontWeight: row.game.duration === 0 ? 600 : 0,
                    width: "50px",
                  }}
                >{`${row.game.duration} min`}</p>
              )
            },
            headerStyle: (colum, colIndex) => {
              return { width: "7%" }
            },
            validator: (newValue, row, column) => {
              //console.log(newValue)
              if (+newValue < 30) {
                return {
                  valid: false,
                  message: "Minimum Playing Time is 30 Minutes",
                }
              }
              if (isNaN(newValue)) {
                return {
                  valid: false,
                  message: "Only numbers are allowed.",
                }
              }
              if (newValue % 1 != 0) {
                return {
                  valid: false,
                  message: "Decimals not allowed.",
                }
              }
              return true
            },
            editorRenderer: (
              editorProps,
              value,
              row,
              column,
              rowIndex,
              columnIndex
            ) => (
              <input
                // disabled={selectedCustomer?.type === "VIP"}
                onWheel={preventValueChangeOnScroll}
                style={{
                  width: "50px",
                  height: "40px",
                  border: "1px solid lightGray",
                  padding: "5px",
                  borderRadius: "5px",
                  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                }}
                type="number"
                defaultValue={value}
                onBlur={e => {
                  //console.log(e.target.value)
                  handleDurationChange(e, rowIndex, row, editorProps)
                  // editorProps.onUpdate(+e.target.value)
                }}
                onFocus={e => e.target.select()} // Retain focus on input field
                autoFocus
              />
            ),
      
            editable: true,
          },
        {
            text: props.t("End Time"),
            dataField: "game.table_end_time",
            formatter: (cell, row) => {
                return moment(row.game.table_end_time).format("HH:mm")
            },
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
            editable: false
        },
        {
            text: props.t("Game Price"),
            dataField: selectedCustomer?.customer_type === "Friend" ? "game.price_for_friend" : selectedCustomer?.customer_type === "Regular" ? "game.price_for_regular" : "game.price_for_vip",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
            editable: false
        },
        {
            dataField: "game.sub_total",
            formatter: (cell, row) => {
              return row.game.sub_total.toFixed(2)
            },
            text: "Sub Total",
            headerStyle: (colum, colIndex) => {
                return { width: "7%" };
            },
            editable: false,
        },
    ]

    // console.log(updatedgameData,"UDg");

    return (
        <>
        <Row>
        <Col lg={5} md={5}>
                       
                        <Card>
                        <Label>{props.t("Customer")}</Label>
                    
                        <Select
                    //   value={selectedCustomer || []}
                     placeholder="Search Customer by name"
                      onChange={selected => {
                        setSelectedCustomer(selected)
                        setDiabeledTracker(false)
                      }}
                      onInputChange={(newValue) => setSearchTerm(newValue)}
                      options={allCustomer}
                      isLoading={loading}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      noOptionsMessage={() => (searchTerm ? "No results found" : "Type to search")}
                      isClearable
                    />
                        </Card>
                        
                      </Col>
                      <Col lg={2} md={5}>
                      <Button
                          onClick={() =>
                            setAddCustomerModal(prev => (prev = !prev))
                          }
                          style={{
                            position: "relative",
                            overflow: "hidden",
                            marginTop:"1.5rem",
                            backgroundColor: "#fdd005",
                            border: "none",
                            color: "black",
                            fontSize: "15px",
                            fontWeight: "500",
                            width: "120px",
                            boxShadow:
                              "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                            transition: "transform 0.3s ease",
                          }}
                          onMouseEnter={e => {
                            e.target.style.transform = "scale(1.05)"
                          }}
                          onMouseLeave={e => {
                            e.target.style.transform = "scale(1)"
                          }}
                        >
                          <i
                            className="mdi mdi-file-plus"
                            style={{
                              fontSize: "15px",
                              marginRight: "2.5px",
                            }}
                          ></i>
                          {props.t("Add New")}
                          <div
                            style={{
                              position: "absolute",
                              top: "0",
                              left: "0",
                              right: "0",
                              bottom: "0",
                              background:
                                "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                              pointerEvents: "none",
                            }}
                          ></div>
                        </Button>
                         </Col>
                      <Col lg={5} md={12}>
                        {/* <Label></Label> */}
                        <div
                          style={{
                            width: "100%",
                            marginTop:"25px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <h6 style={{ color: "#62AEF2" }}>
                            {`Start Time: ${moment(updatedgameData[0]?.game.table_start_time).format(
                              "YYYY-MM-DD HH:mm"
                              )} - End Time: ${moment(updatedgameData[0]?.game.table_end_time).format(
                                "YYYY-MM-DD HH:mm"
                            )}`}
                          </h6>
                        </div>
                      </Col>
        </Row>
            <Row>
                {/* {selectedCustomer!=={} && ( */}
                    <>
                    <Col>
                    <CardTitle>Games</CardTitle>
                    <ToolkitProvider
                        keyField="_id"
                        columns={GameColumn}
                        data={updatedgameData?.length > 0 ? updatedgameData : []}
                    >
                        {propst => (
                            <div>

                                <BootstrapTable
                                    {...propst.baseProps}
                                    noDataIndication={props.t("No data to display.")}
                                    striped
                                    hover
                                    condensed
                                    cellEdit={cellEditFactory({ mode: "click", blurToSave: true })}
                                />
                            </div>
                        )}
                    </ToolkitProvider>
                </Col>
                    </>
                {/* )} */}
            </Row>

            <Row>
                            <Button
                              type="submit"
                              onClick={handleValidSubmit}
                              disabled={diabeledTracker}
                              
                              style={{
                                cursor:  "pointer",
                                position: "relative",
                                overflow: "hidden",
                                backgroundColor: "#fdd005",
                                border: "none",
                                color: "black",
                                fontSize: "15px",
                                fontWeight: "500",
                                width: "128px",
                                marginTop: "1rem",
                                margin: "auto",
                                boxShadow:
                                  "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                transition: "transform 0.3s ease",
                              }}
                              onMouseEnter={e => {
                                e.target.style.transform = "scale(1.05)"
                              }}
                              onMouseLeave={e => {
                                e.target.style.transform = "scale(1)"
                              }}
                            >
                              {props.t("Add Booking")}
                              <div
                                style={{
                                  position: "absolute",
                                  top: "0",
                                  left: "0",
                                  right: "0",
                                  bottom: "0",
                                  background:
                                    "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                  pointerEvents: "none",
                                }}
                              ></div>
                            </Button>

                            {/* </Col> */}
                          </Row>

                           {/* Add New Customer */}
          <Modal
            size="md"
            isOpen={addCustomerModal}
            toggle={() => setAddCustomerModal(prev => (prev = !prev))}
          >
            <ModalHeader
              toggle={() => setAddCustomerModal(prev => (prev = !prev))}
            >
              {`Add New Customer`}
            </ModalHeader>
            <div>
              <ModalBody>
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardBody>
                        <AvForm
                          onValidSubmit={handleAddCustomer}
                          ref={refContainer}
                        >
                          <Row>
                            <Col lg={6}>
                              <AvField
                                label={props.t("Name")}
                                className="form-control"
                                type="text"
                                name="name"
                                placeholder="Enter Name"
                                validate={{ required: { value: true } }}
                                errorMessage={props.t("Name cannot be empty")}
                              />
                            </Col>
                            <br />
                            <Col lg={6}>
                              <AvField
                                label={props.t("Mobile")}
                                className="form-control"
                                type="number"
                                name="mobile"
                                placeholder="Enter Phone Number"
                                validate={{
                                  required: { value: true },
                                  maxLength: {
                                    value: 13,
                                    errorMessage: props.t(
                                      "Maximum 13 characters allowed."
                                    ),
                                  },
                                }}
                                errorMessage={props.t(
                                  "Phone number cannot be empty"
                                )}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={6}>
                              <AvField
                                label={props.t("Customer Type")}
                                className="form-control"
                                type="select"
                                name="customer_type"
                                defaultValue="Regular"
                                placeholder="Select Customer Type"
                                // validate={{ required: { value: true } }}
                                // errorMessage={props.t("Customer Type cannot be empty")}
                              >
                                <option value="">
                                  {props.t("--Select Type--")}
                                </option>
                                <option value="Regular">
                                  {props.t("Regular")}
                                </option>
                                <option value="Friend">
                                  {props.t("Friend")}
                                </option>
                                <option value="VIP">{props.t("VIP")}</option>
                              </AvField>
                            </Col>
                          </Row>
                          <Row>
                            <Button
                              type="submit"
                              style={{
                                position: "relative",
                                overflow: "hidden",
                                backgroundColor: "#fdd005",
                                border: "none",
                                color: "black",
                                fontSize: "15px",
                                fontWeight: "500",
                                width: "150px",

                                margin: "auto",
                                boxShadow:
                                  "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                transition: "transform 0.3s ease",
                              }}
                              onMouseEnter={e => {
                                e.target.style.transform = "scale(1.05)"
                              }}
                              onMouseLeave={e => {
                                e.target.style.transform = "scale(1)"
                              }}
                            >
                              {props.t("Add Customer")}
                              <div
                                style={{
                                  position: "absolute",
                                  top: "0",
                                  left: "0",
                                  right: "0",
                                  bottom: "0",
                                  background:
                                    "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                  pointerEvents: "none",
                                }}
                              ></div>
                            </Button>
                          </Row>
                        </AvForm>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </ModalBody>
            </div>
          </Modal>
        </>
    )
}

export default withRouter(connect(null, {})(withTranslation()(DsbdBookModal)))