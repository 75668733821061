import React, { useCallback, useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  ModalBody,
  ModalHeader,
  Modal,
  ModalFooter,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import Select from "react-select"
import logo from "../../assets/images/Logo.png"
import withRouter from "../../components/Common/withRouter"
import showNotification, { showWarning } from "../../helpers/show_notificaton"
import {
  AvField,
  AvForm,
  AvRadio,
  AvRadioGroup,
} from "availity-reactstrap-validation"
import { postSubmitForm } from "../../helpers/backend_helper"
import BackgroundImage from "../../assets/images/Background.png"
import { Link } from "react-router-dom"
import { formatDate } from "@fullcalendar/core"
import { debounce } from "lodash"


const WalletTransactions = props => {

const refContainer = useRef(null);
const refSelectedCustomer = useRef(null);

useEffect(()=>{
    // loadAllCustomers()
    },[])
    
    const [values, setValues] = useState()
    const [allCustomer, setAllCustomer] = useState([])
    const [selected_Customer, setSelected_Customer] = useState({})
    const [printData, setPrintData] = useState()
    const [walletTransactions, setWalletTransactions] = useState([])
    const [printModal, setPrintModal] = useState()
    const [popupModal, setPopupModal] = useState()

    
      //load All Customers
    // const loadAllCustomers = async () => {
    // let url = process.env.REACT_APP_BASEURL + "customers/getall"
    // let response = await postSubmitForm(url, "")
    
    // if (response.status === 1) {
    //     response.data.map((customer)=>{
    //         customer.label =`${customer.name} [${customer.mobile}] [${customer.customer_type}]`,
    //         customer.value = customer._id;
    //     })
    //     const active =  response.data.filter(cust=>cust.is_active===true && cust.is_wallet_eligible===true)
    //     setAllCustomer(active)
    // } else {
    //     showNotification(response.message, "Error")
    // }
    // }
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearch = useCallback(
      
      debounce(async (searchValue) => {
        // console.log("called");
        // Only search if search term is at least 3 characters
        if (searchValue.length < 3) {
          // setAllCustomer([]);
          setLoading(false);
          return;
        }
  
        try {
          setLoading(true);
          let url = `${process.env.REACT_APP_BASEURL}customers/getall_for_booking`;
          let response = await postSubmitForm(url, { search_query: searchValue });
  
          if (response.status === 1) {
            const formattedCustomers = response.data.map(customer => ({
              ...customer,
              label: `${customer.name} [${customer.mobile}] [${customer.customer_type}]`,
              value: customer._id
            })).filter(cust=>cust.is_wallet_eligible===true);
  
            setAllCustomer(formattedCustomers);
          } else {
            // showNotification(response.message, "Error");
            // setAllCustomer([]);
          }
        } catch (error) {
          showNotification("Error searching customers", "Error");
          setAllCustomer([]);
        } finally {
          setLoading(false);
        }
      }, 800), // 800ms debounce delay
      []
    );
  
    useEffect(() => {
      debouncedSearch(searchTerm);
    }, [searchTerm, debouncedSearch]);
    //Insert
    const handleValidSubmit = async (e,v)=>{
      if(selected_Customer.length<=0){
        showNotification("hdgfgd","Error")
        return
      }
      else {
        let url= process.env.REACT_APP_BASEURL + "customers/wallet_transaction"
        const payload={
          customer_id : selected_Customer._id,
          transaction_type :v.transaction_type,
          transaction_amount :v.transaction_amount,
          transaction_remarks : v.transaction_remarks,
        }
  
        // return;
        let response = await postSubmitForm(url,payload)
        if (response.status === 1 && response.data){
          setPrintData(response.data);
          // setWalletTransactions(response.data.wallet_transactions)
          setWalletTransactions(response.data.wallet_transactions[response.data.wallet_transactions.length-1])
          setPrintModal(!printModal)
          // loadAllCustomers()
          showNotification(response.message,"Success");
          // console.log(response.data)
          // printDiv("printDiv")
          // return;
          refContainer.current.reset()
          setSelected_Customer({})
          setAllCustomer([])
        }else{
          showWarning(response.message,"Warning")
        }
      }
    
    }



    const backgroundImageStyle = {
      backgroundImage: `url(${BackgroundImage})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
    };
      
    const printDiv = (divName) => {
      let content = document.getElementById(divName);
      var printWindow = window.open("",
        "",
        "left=0,top=0,toolbar=0,scrollbars=0,status=0");
      const imageHeight = "105px";
      const imageWidth = "110px";
      const borderradius = "50%";
      const background = "#000000"; 
      printWindow.document.write(`
     
         <html>
         <title>${divName}</title>
         <head> 
          <style>
          table {
            table-layout: fixed;
            margin: auto;
            font-family: "Poppins", sans-serif;
            border: 1px solid #eff2f7;
            font-size: 12px;
            border-collapse: collapse;
            max-width: 80%;
            color: #495057;
          
            margin-bottom: 1rem;
            margin-top: 1rem;
          }
          
          for-print-heading {
            font-size: 16px;
            margin: 0 0 0 0;
            font-weight: bold;
          }
  
          for-print-sub_heading {
            font-size: 14px;
            margin: 0 0 0 0;
            font-weight: normal !important;
          }
          table td, table th {
            border: 1px solid #ddd;
            padding: 8px;
          }
          
          table tr:nth-child(even){background-color: #f2f2f2;}
          
          table tr:hover {background-color: #ddd;}
          
          table th {            
            text-align: left;
          }
           .head{
            display:flex;
            justify-content:space-between;
           }
              #logoImg {
                height: ${imageHeight};
                width: ${imageWidth};
                background-color: ${background};
                border-radius: ${borderradius};
                margin-right: 150px; 
            }
          </style>
         </head>
      <body>
    ${content.innerHTML}
      </body>
         </html>
  
      `);
      printWindow.document.close();
      const logoImg = printWindow.document.getElementById('logoImg');
      logoImg.onload = function () {
        printWindow.focus();
        printWindow.print();
        printWindow.close();
      };
    };
  
   
  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs title={props.t("Customer")}
            breadcrumbItem1={props.t("Wallet Transactions")}
            alternateTitle={props.t("Wallet Transactions")}
            />

          <Row>
            <Col lg={12}>
              <Card>
             
                <CardBody
                 style={{
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                }}
                >
                <CardTitle>
                    <Row>
                      <Col lg={6}>
                      {props.t("Wallet Transaction")}
                      </Col>
                    </Row>
                  </CardTitle>
                  <CardSubtitle>
                    <Row>
                      <Col lg={6}>
                      {props.t("Enter the following details to add a Transaction")}
                      </Col>
                    </Row>
                  </CardSubtitle>
                  <AvForm onValidSubmit={(e,v)=>{
                       if(selected_Customer.length<=0){
                        showWarning("Please Select Customer","Error")
                        return
                      } else{
                        setValues(v);
                        setPopupModal(!popupModal)
                      }
                  }
                  }
                     ref={refContainer}>
                  
                   
                    <Row  className="mb-2 mt-3">             
                       <Col lg={6}>
                       <Label>
                          {props.t("Select Customer")}
                       </Label>
                    
                       <Select
                         value={selected_Customer}
                         placeholder="Search Customer by name"
                         ref={refSelectedCustomer}
                         onChange={(selected)=>{setSelected_Customer(selected)}}
                         onInputChange={(newValue) => setSearchTerm(newValue)}
                         options={allCustomer}
                         isLoading={loading}
                         className="basic-multi-select"
                         classNamePrefix="select"
                         noOptionsMessage={() => (searchTerm ? "No results found" : "Type to search")}
                         isClearable
                        //  closeMenuOnSelect={false}
                         required
                      
                       />
                     

                     </Col>
                 
                     <Col lg={6}>
                        <AvField
                          name="transaction_amount"
                          label={props.t("Amount *")}
                          className="form-control"
                          type="number"
                          placeholder={props.t("Enter Amount")}
                          required={{value: true}}
                          errorMessage={props.t("Please Enter Amount")}
                        />
                        
                      </Col>
                    </Row>

                  
                    <Row>
                    <Col lg={3}>
                        <Label>{props.t("Transaction Type *")}</Label>
                        <AvRadioGroup name="transaction_type" required errorMessage={props.t("Pick one!")} inline>
                      <AvRadio label="Credit" value="Credit" />
                      <AvRadio label="Debit" value="Debit" />
                    </AvRadioGroup>
                      </Col>
                        <Col lg={3} className="mt-2">
                      {selected_Customer?.name && (
                        <Label>{props.t("Current Balance : ")}<span style={{color:`${selected_Customer.wallet>0 ? "green": "red"}`}}>{selected_Customer?.wallet}</span> </Label>
                      )}
                        </Col>
  
                      
                      <Col lg={6}>
                        <AvField
                          name="transaction_remarks"
                          label={props.t("Remarks ")}
                          className="form-control"
                          type="textarea"
                          placeholder={props.t("Enter Remarks")}
                        />
                        
                      </Col>
                    

                    </Row>
                    <hr />
                    <Row>
                      
                    <Button
                                      type="submit"
                                      style={{
                                        position: "relative",
                                        overflow: "hidden",
                                        backgroundColor: "#fdd005",
                                        border: "none",
                                        color: "black",
                                        fontSize: "15px",
                                        fontWeight: "500",
                                        width: "100px",
                                        marginTop: "1.6rem",
                                        margin:"auto",
                                        boxShadow:
                                          "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                        transition: "transform 0.3s ease",
                                      }}
                                      onMouseEnter={e => {
                                        e.target.style.transform = "scale(1.05)"
                                      }}
                                      onMouseLeave={e => {
                                        e.target.style.transform = "scale(1)"
                                      }}
                                    >
                                      {props.t("Submit")}
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "0",
                                          left: "0",
                                          right: "0",
                                          bottom: "0",
                                          background:
                                            "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                          pointerEvents: "none",
                                        }}
                                      ></div>
                                    </Button>
                     

                    </Row>
                  </AvForm>
               
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
    {/* Print Receipt */}
          <Row>
            <Col>
              <Modal
                size="xl"
                isOpen={printModal}
                toggle={() => setPrintModal(prev => prev = !prev)}
              >
                <ModalHeader toggle={() => setPrintModal(prev => prev = !prev)} >
                </ModalHeader >
                <ModalBody>
                  <div id="printDiv">
                    {/* {selectedRow && ( */}
                    <div className="container pl-4  pr-0">
                    <div className="row head " style={{ backgroundColor: " #f0f0f0", height: "100px", borderRadius: "50px 0px 0px 50px" }}>
                        <div className="col col-md-2 col-sm-2" style={{ display: "flex", justifyContent: "flex-start" }}>
                          <img src={logo}  id="logoImg" style={{ height: "105px", borderRadius: " 50%", width: "110px", backgroundColor: "#000000" }} />
                        </div>
                        <div className="col col-md-10 col-sm-10 pt-3" style={{ display: "flex", justifyContent: "flex-end" }}>
                          <p className="" style={{ color: "#000000", fontWeight: "500" }}>
                             <br />
                            {/* PO Id : {selectedRow.po_id}<br /> */}
                            <span style={{ display: "flex", justifyContent: "flex-end", marginTop: "5px" }}>Date &nbsp;{formatDate(walletTransactions?.transaction_date)|| "N/A"}</span>
                            <span style={{ display: "flex", justifyContent: "flex-end", marginTop: "5px" }}>
                             
                              </span>
                          </p>
                        </div>
                      </div>
                      <br />
                      <br />
                      <div className="row ">
                        <h5> Billed To</h5>
                      </div>
                      <p className="mb-0 date">
                        <span style={{ fontWeight: 500 }}>
                          Name: &nbsp;
                          <b>{printData?.name}</b>
                        </span>
                      </p>
                      <p className="mb-0">
                        <span style={{ fontWeight: 500 }}>Phone. :</span> &nbsp;
                        <b>{printData?.mobile}</b>
                      </p>
                      <p className="mb-0">
                        <span style={{ fontWeight: 500 }}>Type: </span> &nbsp;
                        <b>{printData?.customer_type} </b>
                      </p>
                      <hr />
                      <div className="row ">
                        <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12">
                          <div className="table-responsive">
                            <h5>Transaction Details </h5>
                            <table
                              id="example"
                              className="table table-bordered"
                              style={{ width: "100%" }}
                            >
                              <thead className="bg-light">
                                <tr>
                                  <th>S.N</th>
                                  <th>Amount (QR)</th>
                                  <th>Transaction Type</th>                                                         
                                  {/* <th>Transaction Remarks</th>                                                          */}
                                </tr>
                              </thead>
                              <tbody>

                                <tr>
                                  <td>{+1}</td>
                                  <td>{walletTransactions?.transaction_amount}</td>
                                  <td>{walletTransactions?.transaction_type}</td>
                                  {/* <td>{walletTransactions?.transaction_remarks}</td> */}
                                </tr>
                          
                          {/* {walletTransactions && walletTransactions.map(trns=>{
                             <tr key={trns._id}>
                             <td>{index +1}</td>
                             <td>{trns.transaction_amount}</td>
                             <td>{trns.transaction_type}</td>
                             </tr>
                          })} */}
                               
                               
                                <tr>
                                  <td colSpan={2} style={{ textAlign: "end", fontWeight: "bold" }} >Available Balance</td>
                                  <td style={{ fontWeight: "bold" }}>{printData?.wallet||0} &nbsp;QR</td>
                                </tr>
                              </tbody>
                            </table>

                           

                          </div>
                          <hr />

                          {/* <div className="row">
                            <div className="col col-lg-12" style={{ display: "flex", justifyContent: "flex-end" }}>
                              <p className="">
                                <span style={{ fontWeight: 500 }}>
                                  Total Amount &nbsp; &nbsp;      
                               
                                </span>
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col col-lg-12" style={{ display: "flex", justifyContent: "flex-end" }}>
                              <p className="">
                                <span style={{ fontWeight: 500 }}>
                                  Amount Debited &nbsp; &nbsp;      
                                 
                                </span>
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col col-lg-12" style={{ display: "flex", justifyContent: "flex-end" }}>
                              <p className="">
                                <span style={{ fontWeight: 500 }}>
                                  Amount Credited &nbsp; &nbsp;      
                                
                                
                                </span>
                              </p>
                            </div>
                          </div> */}

                          
                          <div className="row mb-5" style={{ display: "flex", justifyContent: "flex-end" }}>

                          </div>
                        </div>
                      </div>
                    </div>
               


                  </div>
                  <Row>
                    <Col style={{display:"flex",justifyContent:"center"}}>
                      <Link to="#" onClick={() => { printDiv("printDiv"); }} className="btn btn-primary">
                        <i className="fa fa-print" /> Print
                      </Link>
                    </Col>
                  </Row>
                </ModalBody>
              </Modal>
            </Col>
          </Row>

         {/* Approve Modal */}
         < Modal isOpen={popupModal} toggle={() => setPopupModal(!popupModal)} >
            <ModalHeader toggle={() => setPopupModal(!popupModal)}>Are you sure </ModalHeader>
            <ModalBody>
              <div>
                <label className="form-label"> Once you submit this, it cannot be revoked. </label>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={()=>setPopupModal(!popupModal)}>
                No
              </Button>
              <Button color="success" onClick={(e) =>{handleValidSubmit(e,values); setPopupModal(!popupModal)}}>
                Yes
              </Button>
            </ModalFooter>
          </Modal >
      </div>
    </React.Fragment>
  )
}

export default withRouter(connect(null, {})(withTranslation()(WalletTransactions)))
