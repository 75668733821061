import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button, Card, CardBody, CardTitle, Col, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import withRouter from './Common/withRouter'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Select from 'react-select'
import showNotification, { showWarning } from '../helpers/show_notificaton'
import { postSubmitForm } from '../helpers/backend_helper'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import preloader from '../helpers/Loader'
import { debounce } from 'lodash'

const ReservedTable = (props) => {
  const { gameData } = props
  let gameDetails = [props.gameData] || [];
  const updatedGameData = gameDetails?.map((game, index) => {
    if (index === 0) {
      return {
        _id: game._id,
        capacity: game.capacity,
        is_active: game.is_active,
        is_occupied: game.is_occupied,
        is_reserved_for_open: game.is_reserved_for_open,
        table_number: game.table_number,
        table_type: game.table_type,
        game: {
          ...game.game,
          duration: 0,
          table_end_time: null,
          table_start_time: new Date(),
        }
      };
    }
    return game;
  });

  const [selectedCustomer, setSelectedCustomer] = useState({})
  useEffect(() => {
    // loadAllCustomers();
    loadAllBranches()
  }, [selectedCustomer]);

  const [branches, setBranches] = useState([])
  const loadAllBranches = async () => {
    let url = process.env.REACT_APP_BASEURL + "branches/getall"
    let response = await postSubmitForm(url, "")
    if (response && response.status === 1) {
      setBranches(response.data)
    } else {
      console.log("Error")
    }
  }
  const [allCustomer, setAllCustomer] = useState([])
  // const loadAllCustomers = async () => {
  //   let url = process.env.REACT_APP_BASEURL + "customers/getall"
  //   let response = await postSubmitForm(url, "")

  //   if (response.status === 1) {
  //     response.data.map(customer => {
  //       ; (customer.label = `${customer.name} [${customer.mobile}] [${customer.customer_type}]`),
  //         (customer.value = customer._id)
  //     })
  //     const active = response.data.filter(cust => cust.is_active === true)
  //     setAllCustomer(active)
  //   } else {
  //     showNotification(response.message, "Error")
  //   }
  // }
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearch = useCallback(
    
    debounce(async (searchValue) => {
      // console.log("called");
      // Only search if search term is at least 3 characters
      if (searchValue.length < 3) {
        // setAllCustomer([]);
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        let url = `${process.env.REACT_APP_BASEURL}customers/getall_for_booking`;
        let response = await postSubmitForm(url, { search_query: searchValue });

        if (response.status === 1) {
          const formattedCustomers = response.data.map(customer => ({
            ...customer,
            label: `${customer.name} [${customer.mobile}] [${customer.customer_type}]`,
            value: customer._id
          }));

          setAllCustomer(formattedCustomers);
        } else {
          // showNotification(response.message, "Error");
          // setAllCustomer([]);
        }
      } catch (error) {
        showNotification("Error searching customers", "Error");
        setAllCustomer([]);
      } finally {
        setLoading(false);
      }
    }, 800), // 800ms debounce delay
    []
  );

  useEffect(() => {
    debouncedSearch(searchTerm);
  }, [searchTerm, debouncedSearch]);
  const [diabeledTracker, setDiabeledTracker] = useState(true)
  const [addCustomerModal, setAddCustomerModal] = useState(false)
  const refContainer = useRef(null)
  const handleAddCustomer = async (e, v) => {
    try {
      const object = {
        name: v.name,
        mobile: v.mobile,
        customer_type: v.customer_type || "Regular",
      }
      let url = process.env.REACT_APP_BASEURL + "customers/insert"
      postSubmitForm(url, object).then(res => {
        if (res.status === 1) {
          refContainer.current.reset()
          setAddCustomerModal(false)
          setAllCustomer([])
          // loadAllCustomers()
        } else {
          showNotification(res.message, "Error")
        }
      })
    } catch (error) {
      showNotification(error.message, "Error")
      console.log(error)
    }
  }

  const handleValidSubmit = async () => {
    if (!selectedCustomer || Object.keys(selectedCustomer).length === 0){
      showWarning("Please select customer","warning")
      return
    }
    preloader(true)
    let url = process.env.REACT_APP_BASEURL + "bookings/insert"
    const payload = {
      check_in: new Date(),
      booking_status: "In Process",
      start_time: updatedGameData[0]?.game.table_start_time,
      end_time:null,
      // game_total_price:gameData[0]?.game.sub_total,
      // total_price:gameData[0]?.game.sub_total,
      package_details: [],
      item_details: [],
      game_details: updatedGameData,
      customer_details: {
        _id: selectedCustomer._id,
        name: selectedCustomer.name,
        mobile: selectedCustomer.mobile,
        customer_type: selectedCustomer.customer_type,
        is_wallet_eligible: selectedCustomer.is_wallet_eligible,
        wallet: selectedCustomer?.wallet,
        address: selectedCustomer.address,
      },
      branch_details: {
        branch_id: branches[0]?._id,
        is_active: branches[0]?.is_active,
        name: branches[0]?.name,
        address: branches[0]?.address,
        email: branches[0]?.email,
        mobile: branches[0]?.mobile,
      },
    }

    postSubmitForm(url, payload)
      .then(response => {
        if (response.status === 1 && response.data) {
          preloader(false)
          props.onClose();
          props.reFresh()
          showNotification(response.message, "Success")
          setSelectedCustomer({})
          setDiabeledTracker(false)
        } else {
preloader(false)
          showNotification(response.message, "Error")
        }
      })
      .catch(err => {
        preloader(false)
        console.log(err)
      })
  }


  return (
    <>

      <Row>
        {/* <Col lg={8} md={8} sm={8}> */}
        <Col >

          <Card>
            <Label>{props.t("Customer")}</Label>

            <Select
              //   value={selectedCustomer || []}
              onChange={selected => {
                setSelectedCustomer(selected)
                setDiabeledTracker(false)
              }}
              onInputChange={(newValue) => setSearchTerm(newValue)}
               placeholder="Search Customer by name"
              options={allCustomer}
              isLoading={loading}
              className="basic-multi-select"
              classNamePrefix="select"
              noOptionsMessage={() => (searchTerm ? "No results found" : "Type to search")}
              isClearable
            />
          </Card>

        </Col>
        {/* <Col lg={2} md={2} sm={2}>
          <Button
            className='btn btn-md'
            onClick={() =>
              setAddCustomerModal(prev => (prev = !prev))
            }
            style={{
              position: "relative",
              overflow: "hidden",
              marginTop: "1.5rem",
              backgroundColor: "#fdd005",
              border: "none",
              color: "black",
              fontSize: "10px",
              fontWeight: "500",
              height: "35px",
              width: "120px",
              boxShadow:
                "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
              transition: "transform 0.3s ease",
            }}
            onMouseEnter={e => {
              e.target.style.transform = "scale(1.05)"
            }}
            onMouseLeave={e => {
              e.target.style.transform = "scale(1)"
            }}
          >
            <i
              className="mdi mdi-file-plus"
              style={{
                fontSize: "15px",
                marginRight: "2.5px",
              }}
            ></i>
            {props.t("Add New")}
            <div
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                right: "0",
                bottom: "0",
                background:
                  "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                pointerEvents: "none",
              }}
            ></div>
          </Button>
        </Col>  <hr /> */}
      </Row>
      <Row> <hr />

        <Button
          type="submit"
          onClick={handleValidSubmit}
          style={{
            cursor: "pointer",
            position: "relative",
            overflow: "hidden",
            backgroundColor: "#fdd005",
            border: "none",
            color: "black",
            fontSize: "15px",
            fontWeight: "500",
            width: "128px",
            marginTop: "1rem",
            margin: "auto",
            boxShadow:
              "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
            transition: "transform 0.3s ease",
          }}
          onMouseEnter={e => {
            e.target.style.transform = "scale(1.05)"
          }}
          onMouseLeave={e => {
            e.target.style.transform = "scale(1)"
          }}
        >
          {props.t("Reserve")}
          <div
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
              background:
                "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
              pointerEvents: "none",
            }}
          ></div>
        </Button>

        {/* Add New Customer */}
        <Modal
          size="md"
          isOpen={addCustomerModal}
          toggle={() => setAddCustomerModal(prev => (prev = !prev))}
        >
          <ModalHeader
            toggle={() => setAddCustomerModal(prev => (prev = !prev))}
          >
            {`Add New Customer`}
          </ModalHeader>
          <div>
            <ModalBody>
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <AvForm
                        onValidSubmit={handleAddCustomer}
                        ref={refContainer}
                      >
                        <Row>
                          <Col lg={6}>
                            <AvField
                              label={props.t("Name")}
                              className="form-control"
                              type="text"
                              name="name"
                              placeholder="Enter Name"
                              validate={{ required: { value: true } }}
                              errorMessage={props.t("Name cannot be empty")}
                            />
                          </Col>
                          <br />
                          <Col lg={6}>
                            <AvField
                              label={props.t("Mobile")}
                              className="form-control"
                              type="number"
                              name="mobile"
                              placeholder="Enter Phone Number"
                              validate={{
                                required: { value: true },
                                maxLength: {
                                  value: 13,
                                  errorMessage: props.t(
                                    "Maximum 13 characters allowed."
                                  ),
                                },
                              }}
                              errorMessage={props.t(
                                "Phone number cannot be empty"
                              )}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6}>
                            <AvField
                              label={props.t("Customer Type")}
                              className="form-control"
                              type="select"
                              name="customer_type"
                              defaultValue="Regular"
                              placeholder="Select Customer Type"
                            // validate={{ required: { value: true } }}
                            // errorMessage={props.t("Customer Type cannot be empty")}
                            >
                              <option value="">
                                {props.t("--Select Type--")}
                              </option>
                              <option value="Regular">
                                {props.t("Regular")}
                              </option>
                              <option value="Friend">
                                {props.t("Friend")}
                              </option>
                              <option value="VIP">{props.t("VIP")}</option>
                            </AvField>
                          </Col>
                        </Row>
                        <Row>
                          <Button
                            type="submit"
                            style={{
                              position: "relative",
                              overflow: "hidden",
                              backgroundColor: "#fdd005",
                              border: "none",
                              color: "black",
                              fontSize: "15px",
                              fontWeight: "500",
                              width: "150px",

                              margin: "auto",
                              boxShadow:
                                "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                              transition: "transform 0.3s ease",
                            }}
                            onMouseEnter={e => {
                              e.target.style.transform = "scale(1.05)"
                            }}
                            onMouseLeave={e => {
                              e.target.style.transform = "scale(1)"
                            }}
                          >
                            {props.t("Add Customer")}
                            <div
                              style={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                right: "0",
                                bottom: "0",
                                background:
                                  "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                pointerEvents: "none",
                              }}
                            ></div>
                          </Button>
                        </Row>
                      </AvForm>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </ModalBody>
          </div>
        </Modal>

      </Row>
    </>
  )
}

export default withRouter(connect(null, {})(withTranslation()(ReservedTable)))