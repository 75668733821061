import React, { useMemo } from "react"
import { Navigate, useLocation} from "react-router-dom"

const Authmiddleware = props => {
  const rolePaths = {
    Accountant: [
      "/all-booking",
      "/all-sold-packages",
      "/item-sold-only",
      "/wallet-history",
      "/generate_po",
      "/all_purchase_order",
      "/view_inventory",
      "/used_items",
      "/edit_attendance",
      "/apply-leave-owner",
      "/view-attendance",
      "/apply-overtime-owner",
      "/approve_overtime",
      "/roster",
      "/generate-salary",
      "/view-salary",
      "/apply-loan-owner",
      "/arrear-deduction",
      "/completed_loan",
      "/daily_closure",
      "/add_new_employee",
      "/view_employee",
      "/designation",
      "/holiday_master",
      "/shift",
      "/user-changepassword",
      "/under-stock",
      "/out-of-stock-items",
      "/item-category",
      "/products_for_sale",
      "/used-items-report",
      "/threshold-report",
      "/expired-items-reports",
      "/upcoming-expiry-items",
      "/discarded-items-report",
      "/expirydate-logs",
      "/view_employee?view=" + "report",
      "/daily_attendance_report",
      "/employee_attendance_report",
      "/leave_report",
      "/total_sales_details",
      "/game-sales-report",
      "/item-sales-report",
      "/package-sales-report",
      "/costing-report",
      "/customer-visit-frequency",
      "/top-20-customers",
      "/list_of_customers",
      "/accounts-report",
      "/view_daily_closer_record",
    ],
    Cashier: [
      "/add-reservation",
      "/game-bookings-table",
      "/add-games",
      "/add-customer",
      "/add-items",
      "/all-booking",
      "/instant_reservation",
      "/apply-loan-owner",
      "/invoice_details",
      "/notification",
      "/sell-items",
      "/item-sold-only",
      "/sell-packages",
      "/view-emp-attendance",
      "/daily_closure",
      "/shift_closure",
      "/user-changepassword",
      "/check-out",
      "/all-sold-packages",
      "/apply-leave-owner",
      "/apply-overtime-owner",
      "/wallet-transactions",
      "/wallet-history"
    ],
    Kitchen: [
      "/employee-kitchen-order",
      "/view-emp-attendance",
      "/user-changepassword",
      "/used_items",
      "/sell-items",
      "/new-orders",
      "/new-orders?type=" + "Kitchen",
    ],
    Cafeteria: [
      "/sell-items",
      "/view-emp-attendance",
      "/user-changepassword",
      "/employee-kitchen-order",
      "/item-sold-only",
      "/new-orders",
      "/new-orders?type=" + "Cafe",
    ],
    Manager: [
      "/dashboard",
      "/add_new_employee",
      "/sell-items",
      "/item-sold-only",
      "/sell-packages",
      "/all-sold-packages",
      "/add-games",
      "/add-items",
      "/all-booking",
      "/apply-overtime-owner",
      "/apply-leave-owner",
      "/view_inventory",
      "/expired_items",
      "/add_supplier",
      "/update_supplier",
      "/add_item",
      "/unit-of-measurement",
      "/add_category",
      "/add_category",
      "/daily_closure",
      "/shift_closure",
      "/users",
      "/designation",
      "/holiday_master",
      "/shift",
      "/wallet-history",
      "/wallet-transactions",
      "/add_games",
      "/add-customer",
      "/add_expense",
      "/add_source",
      "/add_game_tables",
      "/add_packages",
      "/all_purchase_order",
      "/payment_methods",
      "/coupons",
      "/user-changepassword",
      "/view_employee",
      "/mark-attendance",
      "/edit_attendance",
      "/view-attendance",
      "/approve_overtime",
      "/approve_leave",
      "/view-salary",
      "/apply-loan-owner",
      "/loan",
      "/completed_loan",
      "/generate_po",
      "/kitchen_withdraw",
      "/accessories-order",
      "/used_items",
      "/add-reservation",
      "/check-out",
      "/roster",
    ],
    "Super User": [
      "/dashboard",
      "/add_new_employee",
      "/sell-items",
      "/item-sold-only",
      "/sell-packages",
      "/all-sold-packages",
      "/add-games",
      "/add-items",
      "/refund",
      "/all-booking",
      "/apply-overtime-owner",
      "/apply-leave-owner",
      "/view_inventory",
      "/expired_items",
      "/add_supplier",
      "/update_supplier",
      "/add_item",
      "/unit-of-measurement",
      "/add_category",
      "/add_category",
      "/daily_closure",
      "/shift_closure",
      "/users",
      "/designation",
      "/holiday_master",
      "/shift",
      "/wallet-history",
      "/wallet-transactions",
      "/add_games",
      "/add-customer",
      "/add_expense",
      "/add_source",
      "/add_game_tables",
      "/add_packages",
      "/all_purchase_order",
      "/payment_methods",
      "/coupons",
      "/user-changepassword",
      "/view_employee",
      "/mark-attendance",
      "/edit_attendance",
      "/view-attendance",
      "/approve_overtime",
      "/approve_leave",
      "/view-salary",
      "/apply-loan-owner",
      "/loan",
      "/completed_loan",
      "/generate_po",
      "/kitchen_withdraw",
      "/accessories-order",
      "/used_items",
      "/add-reservation",
      "/check-out",
      "/roster",
    ],
    "Store Keeper": [
      "/user-changepassword",
      "/all_purchase_order",
      "/view_inventory",
      "/expired_items",
      "/add-customer",
      "/apply-overtime-owner",
      "/apply-leave-owner",
      "/add_supplier",
      "/update_supplier",
      "/add_item",
      "/add_category",
      "/update_category",
      "/unit-of-measurement",
      "/apply-loan-owner",
      "/view-emp-attendance",
    ],
  }
  const location = useLocation()
  const token = localStorage.getItem("userToken");
  const role = localStorage.getItem("role");
  const allowedPaths = useMemo(() => rolePaths[role] || [], [role]);

  function getPath () {
    let path = ''
    if (role === "Admin" || role === "Manager" || role === "Super User" ||role === "Owner") {
       path= "/dashboard"
     } else{
       path= "/employee-dashboard"
     }    
    return path
  }
  if (!token) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    )
  }else{
    if (allowedPaths.includes(location.pathname)) {
      return <>{props.children}</>;
    }
    if ((role === "Owner" || role === "Admin") &&
    location.pathname !== "/apply-leave" &&
    location.pathname !== "/employee-dashboard" &&
    location.pathname !== "/attendance" &&
    location.pathname !== "/apply-overtime" &&
    location.pathname !== "/apply-loan" &&
    location.pathname !== "/item_order"
  ) {
    return <React.Fragment>{props.children}</React.Fragment>
  }
  else if ( role !== "Owner" &&
    (location.pathname === "/apply-leave" ||
    location.pathname === "/notification" ||
      location.pathname === "/employee-dashboard" ||
      location.pathname === "/attendance" ||
      location.pathname === "/apply-overtime" ||
      location.pathname === "/apply-loan" ||
      location.pathname === "/user-changepassword" ||
      location.pathname === "/item_order")
  ) {
    return <React.Fragment>{props.children}</React.Fragment>
  }
  else  {
    return (
    <Navigate to={{ pathname: `${getPath()}`, state: { from: props.location } }} />
  )}
  } 
}

export default Authmiddleware
