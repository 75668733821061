import React, { useCallback, useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Button,
  CardTitle,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  ModalHeader,
  Modal,
  ModalBody,
  FormGroup,
  Input,
} from "reactstrap"
import classnames from "classnames";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import withRouter from "../../components/Common/withRouter"
import showNotification, { showWarning } from "../../helpers/show_notificaton"
import {
  AvField,
  AvForm,
} from "availity-reactstrap-validation"
import { postSubmitForm, postSubmitForm_withformdata } from "../../helpers/backend_helper"
import ToolkitProvider, { Search, } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import Select from "react-select"
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from "moment";
import swal from "sweetalert2"
import axios from "axios"
import BackgroundImage from "../../assets/images/Background.png"
import preloader from "../../helpers/Loader";
import preventValueChangeOnScroll from "../../helpers/PreventValueOnScroll";
import { debounce } from "lodash";

const BuyPackages = props => {
  document.title = "Sell Packages"

  const refSelectGame = useRef(null);
  const refContainer = useRef(null);
  const { SearchBar } = Search

  const [paidAmount, setPaidAmount] = useState(0)
  //Tabs
  const [verticalActiveTab, setverticalActiveTab] = useState("1");
  const [editModal, setEditModal] = useState()
  const [addCustomerModal, setAddCustomerModal] = useState(false)
  const [selected_Customer, setSelected_Customer] = useState()
  const [selected_Packages, setSelected_Packages] = useState([])
  const [allPackages, setAllPackages] = useState([])
  const [package_Item_Details, setPackage_Item_Details] = useState([])
  const [grandTotal, setGrandTotal] = useState("0")
  const [remarks, setRemarks] = useState()
  const [discount, setDiscount] = useState()
  const [paid_by_cash, setPaid_by_cash] = useState("0")
  const [paid_by_card, setPaid_by_card] = useState("0")
  const [from_wallet, setFrom_wallet] = useState("0")
  const [final_amount, setFinal_Amount] = useState()
  const [paid_by_wallet, setPaid_by_wallet] = useState("0")
  const [discountPercentage, setDiscountPercentage] = useState(0)
  const [postdiscountAmount, setPostdiscountAmount] = useState(0)
  const [discountInput, setDiscountInput] = useState("")
  const [discountTracker, setDiscountTracker] = useState(false)
  const [package_Game_Details, setPackage_Game_Details] = useState([])
  const [selectedRow, setSelectedRow] = useState({ game_details: [], item_details: [] });
  const [isExistingBooking, setIsExistingBooking] = useState(false);
  const [booked_Customer, setbooked_Customer] = useState([])
  const [existBookinCustomer, setExistBookinCustomer] = useState([])
  const [allCustomer, setAllCustomer] = useState([])
  //for pagination
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  // Payment Methods
  const [paymentMethods, setPaymentMethods] = useState([])
  const [selectedPaymentMethods, setSelectedPaymentMethods] = useState([])
  const [formFieldValues, setFormFieldValues] = useState({})
  const [paymentDetails, setPaymentDetails] = useState([])
  //Package Validation
  const [packageValidation, setPackageValidation] = useState(true)
  const [branches, setBranches] = useState([])
  const [remaining, setRemaining] = useState(0)
  const [changeAmount, setChangeAmount] = useState(0);
  useEffect(() => {
    // loadAllCustomers()
    loadAllPackages()
    loadAllPaymnets()
    loadAllBranches()
  }, []);

  const getWallet = async () => {
    setFrom_wallet(selected_Customer?.wallet)
  }
  useEffect(() => {
    getWallet()
  }, [selected_Packages])


  const loadAllBranches = async () => {
    let url = process.env.REACT_APP_BASEURL + "branches/getall"
    let response = await postSubmitForm(url, "")
    if (response && response.status === 1) {
      setBranches(response.data)
    } else{
      console.log("Error")
    }
  }

  const applyDiscount = async () => {
    if (!discountInput || typeof discountInput !== "string") {
      swal.fire({
        title: "Invalid Coupon Code",
        text: "Please enter a valid coupon code.",
        icon: "error",
      });
      return;
    }
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASEURL + "coupons/getall",
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );

      if (response.data.status === 1) {
        const enteredCoupon = response.data.data.find(
          coupon => coupon.coupon_code === discountInput && coupon.is_active===true
        );

        if (enteredCoupon) {
          const couponDiscount = enteredCoupon.discount_percent;
          setDiscountPercentage(couponDiscount)
          const discountedTotal = grandTotal * (1 - couponDiscount / 100);
          const discountAmount = (grandTotal - discountedTotal);
          setPostdiscountAmount(Math.round(discountAmount))
          setFinal_Amount(Math.round(discountedTotal))
          setRemaining(Math.round(discountedTotal))
          setDiscountTracker(true);

          swal.fire({
            title: "Coupon Applied",
            text: `Coupon "${discountInput}" applied successfully for ${couponDiscount}% discount.`,
            icon: "success",
          });
        }
        else {
          swal.fire({
            title: "Coupon Not Found",
            text: `Coupon "${discountInput}" is not valid.`,
            icon: "error",
          });
        }
      } else {
        swal.fire("Error", response.data.message, "error")
      }
    } catch (error) {

      if (error.response) {
        swal.fire("Error", error.response.data.message, "error")
      } else {
        swal.fire(
          "Error",
          "An error occurred while applying the coupons.",
          "error"
        )
      }

    }
  }

 
  //load All Customers
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
 
  const debouncedSearch = useCallback(
    debounce(async (searchValue) => {
      // Only search if search term is at least 3 characters
      if (searchValue.length < 3) {
        // setAllCustomer([]);
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        let url = `${process.env.REACT_APP_BASEURL}customers/getall_for_booking`;
        let response = await postSubmitForm(url, { search_query: searchValue });

        if (response.status === 1) {
          const formattedCustomers = response.data.map(customer => ({
            ...customer,
            label: `${customer.name} [${customer.mobile}] [${customer.customer_type}]`,
            value: customer._id
          }));

          setAllCustomer(formattedCustomers);
        } else {
          // showNotification(response.message, "Error");
          setAllCustomer([]);
        }
      } catch (error) {
        showNotification("Error searching customers", "Error");
        setAllCustomer([]);
      } finally {
        setLoading(false);
      }
    }, 500), // 500ms debounce delay
    []
  );

  useEffect(() => {
    debouncedSearch(searchTerm);
  }, [searchTerm, debouncedSearch]);

  const loadAllPaymnets = async () => {
    let url = process.env.REACT_APP_BASEURL + "payment_methods/getall"
    let response = await postSubmitForm(url, "")

    if (response.status === 1) {
      if (selected_Customer?.is_wallet_eligible === false) {
        const active = response.data.filter(e => e.name !== "Wallet" && e.is_active === true);
        active.sort((a, b) => a.name.localeCompare(b.name));
        setPaymentMethods(active)
      } else {
        const active = response.data.filter(e => e.is_active === true);
        active.sort((a, b) => a.name.localeCompare(b.name));
        setPaymentMethods(active)
      }

    } else {
      showNotification(response.message, "Error")
    }
  }

  useEffect(() => {
    loadAllPaymnets()
    setSelectedPaymentMethods([])
    refContainer.current.reset()
    setFormFieldValues({})
    setPaymentDetails([])
  }, [selected_Customer])


  function handleSelect(data) {
    setPackageValidation(false)
    setSelected_Customer(data)
  }

  //Tabs Function
  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab);
    }
  };


  //Handle Pya,ment Methods
  const handlePaymentMethodChange = selectedOptions => {
    const newPaymentDetails = selectedOptions.map(selectedOption => {
      const fieldName = `paymentMethod_${selectedOption.value}`;
      const existingDetail = paymentDetails.find(detail => detail._id === selectedOption.value);
  
      return {
        _id: selectedOption.value,
        [selectedOption.label]: existingDetail ? existingDetail[selectedOption.label] : "",
      };
    });
  
    setPaymentDetails(newPaymentDetails);
  
    const totalPaidAmount = newPaymentDetails.reduce((total, paymentMethod) => {
      for (const key in paymentMethod) {
        if (key !== "_id") {
          total += parseFloat(paymentMethod[key]) || 0;
        }
      }
      return Math.round(total)
    }, 0);
  
    let Total = final_amount ? final_amount : grandTotal;
    const newRemainingPaidAmount =
      Total - totalPaidAmount - parseFloat(paid_by_wallet)
    setRemaining(newRemainingPaidAmount.toFixed(2))
    
    const unselectedOptions = selectedPaymentMethods.filter(option => !selectedOptions.includes(option));
    unselectedOptions.forEach(unselectedOption => {
      const fieldName = `paymentMethod_${unselectedOption.value}`;
      setFormFieldValues(prevValues => ({
        ...prevValues,
        [fieldName]: "",
      }));
    });
  
    setSelectedPaymentMethods(selectedOptions);
  }

  const calculateFinalPaidAmount = () => {

    let totalPaidAmount = 0
    selectedPaymentMethods.forEach((selectedOption) => {
      const fieldName = `paymentMethod_${selectedOption.value}`;
      const value = parseFloat(formFieldValues[fieldName]) || 0;
      totalPaidAmount += Math.ceil(value);
    });

    const walletAmount = parseFloat(paid_by_wallet) || 0
    totalPaidAmount += walletAmount

    return Math.round(totalPaidAmount)
  }

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} - {to} of {size} records.
    </span>
  );

  const options = {
    paginationSize: 10,
    pageStartIndex: 1,
    // hideSizePerPage: true,
    // hidePageListOnlyOnePage: true,
    onPageChange: (page, sizePerPage) => {
      setPageNumber(page);
      setPageSize(sizePerPage);
    },
    firstPageText: "First",
    prePageText: "Prev",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
       {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
      {
        text: "300",
        value: 300,
      },
      {
        text: "All",
        value: allPackages && allPackages.length,
      },
    ],
    hideSizePerPage: true,
    ignoreSinglePage: true,
  };

function clearStates(){
  setChangeAmount((prev)=> (prev=0))
  setIs_Refund(false)
  setAllCustomer([])
  setRefundAmount(0)
  setRefundRemarks("")
  setRefundType("")
  setIsNoChange(false)
  setSelected_Packages([])
  setSelected_Customer([])
  setbooked_Customer()
  setGrandTotal()
  setFinal_Amount((prev) => (prev = 0))
  setGrandTotal((prev) => (prev = 0))
  totalPaid = 0
  setPaymentDetails([])
  setRemarks("")
  setFormFieldValues({})
  setDiscountInput()
  setPackageValidation(true)
  setDiscountPercentage()
  setPaymentDetails([])
  setPaidAmount()
  setSelectedPaymentMethods([])
  setPaid_by_card("0")
  setPaid_by_cash("0")
  setPaid_by_wallet("0")
  setIsExistingBooking(false)
}
  // insert Customer
  const handleAddCustomer = async (e, v) => {
    try {
      const object = {
        name: v.name,
        mobile: v.mobile,
        customer_type: v.customer_type || "Regular",
      }
      // console.log(object);
      let url = process.env.REACT_APP_BASEURL + "customers/insert"
      postSubmitForm(url, object).then(res => {
        if (res.status === 1) {
          refContainer.current.reset()
          showNotification(res.message, "Success")
          setAddCustomerModal(false)
          // loadAllCustomers()
        } else {
          showNotification(res.message, "Error")
        }
      })
    } catch (error) {
      showNotification(error.message, "Error")
      console.log(error)
    }
  }

  const [isNoChange, setIsNoChange] = useState(false)
  const [is_Refund, setIs_Refund] = useState(false)
  const [refundAmount, setRefundAmount] = useState(0)
  const [refundRemarks, setRefundRemarks] = useState("")
  const [refundType, setRefundType] = useState("")
  const handleValidSubmit = async (e, v) => {
      preloader(true)
    let url = process.env.REACT_APP_BASEURL + 'bookings/insert'
    const obj = {
      is_package_only: true,
       customer_details: {
        _id: selected_Customer._id,
        name: selected_Customer.name,
        mobile: selected_Customer.mobile,
        customer_type: selected_Customer.customer_type,
        wallet: selected_Customer.wallet,
        is_wallet_eligible: selected_Customer.is_wallet_eligible,
      },
          
      package_details: [
        ...selected_Packages.map(item => (
          {
            game_details: item.game_details,
            item_details: item.item_details,
            is_active: item.is_active,
            price: item.price,
            package_name: item.package_name,
            package_id:item.package_id,
            total_sales_limit: item.total_sales_limit,
            per_customer_selling_limit: item.per_customer_selling_limit,
            validity: item.validity,
            _id: item._id,
            total_item_count: item.item_details.reduce((acc, item) => ({
              in_unit: acc.in_unit + (item.available?.in_unit || 0),
              in_sub_unit: acc.in_sub_unit + (item.available?.in_sub_unit || 0),
            }),
              { in_unit: 0, in_sub_unit: 0 }
            ),
            total_game_time: item.game_details.reduce((acc, game) => acc + (game.duration || 0), 0),
          }
        ))
      ],
      item_details: [],
      game_details: [],
      remarks: remarks,
      payment_details: paymentDetails,
      total_price: Math.round(totalPaid),
      branch_details: {
        branch_id: branches[0]?._id,
        is_active: branches[0]?.is_active,
        name: branches[0]?.name,
        address: branches[0]?.address,
        email: branches[0]?.email,
        mobile: branches[0]?.mobile
      },
    }
    //  console.log(obj,"paylaod");
    // return;
    postSubmitForm(url, obj)
      .then(response => {
        if (response.status === 1 && response.data) {
          let id = response.data._id;
          // const payloadSecond = response.data;
          // const payoad2 = {...payloadSecond,booking_id:id }
          // setPayload2(...payoad2, {})
          // return;
          url = process.env.REACT_APP_BASEURL + "sales/check_out"
          const payload2 = {
            booking_id: id,
            is_package_only: true,
            customer_details: {
              _id: selected_Customer._id,
              name: selected_Customer.name,
              mobile: selected_Customer.mobile,
              customer_type: selected_Customer.customer_type,
              wallet: selected_Customer.wallet,
              address: selected_Customer.address
            },
            package_details: [
              ...selected_Packages.map(packageItem => ({
                game_details: packageItem.game_details.map(game=> ({
                  ...game,
                  package_game_remaining: game?.duration,
                 
                  package_game_transactions: [
                    {
                        duration: game.duartion,
                        transaction_type: "credit",
                        transaction_date: new Date(),
                    }],

                })),
                is_active: packageItem.is_active,
                package_name: packageItem.package_name,
                price: packageItem.price,
                validity: packageItem.validity,
                _id: packageItem._id,
                package_id:packageItem.package_id,
                total_sales_limit: packageItem.total_sales_limit,
                per_customer_selling_limit: packageItem.per_customer_selling_limit,
                item_details: packageItem.item_details.map(item => ({
                  ...item,

                  new_available: {
                    in_unit: item.available?.in_unit,
                    in_sub_unit: item.available?.in_sub_unit,
                  },
                
                  package_item_remaining:{
                    in_unit: item.available?.in_unit,
                    in_sub_unit: item.available?.in_sub_unit,
                  },
                  package_item_transactions: [
                    {
                        transaction_quantity: {
                            in_unit: item.available?.unit,
                            in_sub_unit: item.available?.sub_unit
                        },
                        transaction_type: "credit",
                        transaction_date: new Date(),
                    }],
                  quantity: {
                    in_unit: 0,
                    in_sub_unit: 0,
                  },
                })),

                total_item_count: packageItem.item_details.reduce((acc, item) => ({
                  in_unit: acc.in_unit + (item.available?.in_unit || 0),
                  in_sub_unit: acc.in_sub_unit + (item.available?.in_sub_unit || 0),
                }),
                  { in_unit: 0, in_sub_unit: 0 }
                ),

                total_game_time: packageItem.game_details.reduce((acc, game) => acc + (game.duration || 0), 0),
             
              })),
            ],
            branch_details: {
              branch_id: branches[0]?._id,
              is_active: branches[0]?.is_active,
              name: branches[0]?.name,
              address: branches[0]?.address,
              email: branches[0]?.email,
              mobile: branches[0]?.mobile
            },
            item_details: [],
            game_details: [],
            remarks: remarks,
            discount: +discountPercentage || 0,
            discount_amount: +postdiscountAmount.toFixed(2) || 0,
            difference: Math.ceil(grandTotal) - grandTotal,
            paid_amount: selected_Customer?.customer_type === "VIP" ? 0 : Math.round(final_amount) ? Math.round(final_amount) : Math.round(grandTotal),
            total_amount: selected_Customer?.customer_type === "VIP" ? 0 : Math.round(grandTotal) || 0,
            final_amount: selected_Customer?.customer_type === "VIP" ? 0 : final_amount ? Math.round(final_amount) : Math.round(grandTotal),
            change_amount: !isNoChange ? +changeAmount :0 ,
            difference_amount: isNoChange ? +changeAmount:0 ,
            payment_details: paymentDetails,
          }
          // console.log(payload2);
          // return
          // console.log(payoad2);
          postSubmitForm(url, payload2).then(response => {
              const responseData = response.data
              const invoiceUrl = `${window.location.origin}/pkgInvoice?booking_id=` + responseData?.booking_id
              const newTab = window.open(`${invoiceUrl}`, '_blank');
              if (newTab) {
                newTab.focus()
              }
            preloader(false)
            clearStates()
            // loadAllCustomers()
            loadAllPackages()
            loadAllPaymnets()
            showNotification("Package Purchased Successfully", "Success")
             window.location.reload()
            // refContainer.current.reset()
          })
        } else {
          preloader(false)
          showNotification(response.message, "Error")
        }
      })
  }


  //for round image view
  function showPhotoFormatter(cell, row) {
    return (
      <>
        <img
          src={row.image_url || row.image + "?" + new Date() + "=" + new Date()}
          alt={row.name}
          className="avatar-md rounded-circle img-thumbnail"
        />
        <span
          style={{ cursor: "pointer" }}
          className="font-size-12 badge-soft-info badge badge-success badge-pill"
        >
        </span>
      </>
    )
  }


  //All Packages
  const loadAllPackages = async () => {
    let url = process.env.REACT_APP_BASEURL + "packages/getall"
    let response = await postSubmitForm(url, "")
    if (response.status === 1) {
      const format = response.data.filter(pkg => pkg.is_active===true && pkg.validity <= new Date())
      // console.log(response.data);
      format.map((packages) => {
        packages.label = packages.package_name;
        packages.value = packages.package_name;
      })
      setAllPackages(format)
    } else {
      showNotification(response.message,"Error")
    }
  }


  //Action
  function editFormatter(cell, row) {
    return (
      <span className="text-info">
        <i
          className="bx bxs-file font-size-15"
          title="Click to see details"
          style={{ cursor: "pointer", color: "blue" }}
          onClick={() => {
            setEditModal(!editModal)
            setSelectedRow(row)
            setPackage_Item_Details(row.item_details)
            setPackage_Game_Details(row.game_details)
          }}
        ></i>
      </span>
    )
  }


  const handleChangeAmount = ()=>{
    const change = Math.abs(
      final_amount
        ? final_amount - totalPaid
        : grandTotal - totalPaid
    )
    
    let famount = final_amount ? final_amount : grandTotal
    useEffect(()=>{
      if(totalPaid > famount){
        setChangeAmount((prev)=> (prev = change))
      }else{
        setRefundType("")
        setChangeAmount(prev=>prev = 0)
      }
    }, [change])
  }


  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        const rowNumber = (+pageNumber - 1) * +pageSize + (+rowIndex + 1);
        return rowNumber;
      },
      text: props.t("S.N"),
      headerStyle: (colum, colIndex) => {
        return { width: "1%" }
      },
    },
    {
      text: props.t("Name"),
      dataField: "package_name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "3%" }
      },
    },
    {
      text: props.t("Price"),
      dataField: "price",
      sort: false,
      formatter: (cell, row) => {
        return row.price.toFixed(2) + " " + "Qr"
      },
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Validity (days)"),
      dataField: "validity",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Per Customer Sales Limit"),
      dataField: "per_customer_selling_limit",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Total Sales Limit"),
      dataField: "total_sales_limit",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Package Created Date"),
      dataField: "createdAt",
      sort: true,
      formatter: (cell, row) => {
        const timestamp = row.createdAt;
        const formattedDate = moment(timestamp).format('YYYY-MM-DD');
        return formattedDate;
      },
      headerStyle: (colum, colIndex) => {
        return { width: "4%" }
      },
    },
    {
      dataField: "",
      text: props.t("Action"),
      formatter: editFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "3%" }
      },
    },
  ]

  const PackageItemDetails = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        const rowNumber = (+pageNumber - 1) * +pageSize + (+rowIndex + 1);
        return rowNumber;
      },
      text: props.t("S.N"),
      headerStyle: (colum, colIndex) => {
        return { width: "1%" }
      },
    },
    {
      text: props.t("Image"),
      formatter: showPhotoFormatter,
      dataField: "image_url",
      //sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Name"),
      dataField: "name",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Unit"),
      dataField: "available.in_unit",
      sort: false,
      formatter: (cell, row) => {
        return row.available.in_unit + " " + row.unit
      },
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Sub-Unit"),
      dataField: "available.in_sub_unit",
      sort: false,
      formatter: (cell, row) => {
        return row.available.in_sub_unit + " " + row.sub_unit
      },
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    // {
    //   text: props.t("Regular Price"),
    //   dataField: "selling_price",
    //   sort: false,
    //   formatter: (cell, row) => {
    //     let price = row.selling_price / row.formula;
    //     return price.toFixed(2) + " " + "Qr"
    //   },
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "5%" }
    //   },
    // },
  ]

  const PackageGameDetails = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        const rowNumber = (+pageNumber - 1) * +pageSize + (+rowIndex + 1);
        return rowNumber;
      },
      text: props.t("S.N"),
      headerStyle: (colum, colIndex) => {
        return { width: "1%" }
      },
    },
    {
      text: props.t("Image"),
      formatter: showPhotoFormatter,
      dataField: "image",
      //sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Name"),
      dataField: "name",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Minutes"),
      dataField: "duration",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    // {
    //   text: props.t("Price"),
    //   dataField: "price",
    //   sort: false,
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "5%" }
    //   },
    // },
  ]

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",


  };

  let totalPaid = paymentDetails.reduce((total, paymentMethod) => {
    for (const key in paymentMethod) {
      if (key !== "_id") {
        total += parseFloat(paymentMethod[key]) || 0;
      }
    }
    return Math.round(total);
  }, 0);


  function CheckAmounts (e,v){
    const isVipCustomer = selected_Customer?.customer_type === "VIP";
    const isWalletPayment = selectedPaymentMethods[0]?.label === "Wallet";
    const walletBalance = selected_Customer?.wallet||0;
    const requiredAmount = final_amount ? +final_amount : +grandTotal;
    const RemainingCreditLimit = walletBalance <=0 ? 3000 + walletBalance : 0
    const newBalace = walletBalance <=0 ? RemainingCreditLimit : walletBalance+3000
    const walletEntry = paymentDetails.find(entry => 'Wallet' in entry);
    const walletAmount = walletEntry ? walletEntry.Wallet : 0;  
  // return
    if (isVipCustomer) {
      handleValidSubmit(e, v);
    } else if (isWalletPayment) {
      if (walletAmount > newBalace) {
        showWarning("Wallet credit balance limit reached", "warning");
      } else if (totalPaid >= requiredAmount) {
        handleValidSubmit(e, v);
      } else {
        showNotification("Total amount does not match entered amount.", "Error");
      }
    } else {
      if (totalPaid >= requiredAmount) {
        handleValidSubmit(e, v);
      } else {
        showNotification("Total amount does not match entered amount.", "Error");
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs title="Sales" breadcrumbItem1="Sell Packages" alternateTitle="Sell Packages" />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  <AvForm
                    onValidSubmit={(e,v) => {CheckAmounts(e,v)}}
                    ref={refContainer}>
  

                    <Row >
                                           {!isExistingBooking && (
                        <>
                          <Col lg={4} className="mb-2 mt-3">
                            <Label>
                              Customer

                            </Label>

                            <Select
                              ref={refSelectGame}
                              value={selected_Customer}
                              placeholder="Search Customer by name"
                              onChange={handleSelect}
                              onInputChange={(newValue) => setSearchTerm(newValue)}
                              options={allCustomer}
                              isLoading={loading}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              noOptionsMessage={() => (searchTerm ? "No results found" : "Type to search")}
                              isClearable
                              //  closeMenuOnSelect={false}
                              required

                            />
                          </Col>

                          <Col lg={2}

                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              paddingTop: "22px",
                            }}>
                            <Button
                              onClick={() =>
                                setAddCustomerModal(prev => (prev = !prev))
                              }
                              style={{
                                position: "relative",
                                overflow: "hidden",
                                backgroundColor: "#fdd005",
                                border: "none",
                                color: "black",
                                fontSize: "15px",
                                fontWeight: "500",
                                width: "120px",
                                marginTop: "0.6rem",
                                boxShadow:
                                  "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                transition: "transform 0.3s ease",
                              }}
                              onMouseEnter={e => {
                                e.target.style.transform = "scale(1.05)"
                              }}
                              onMouseLeave={e => {
                                e.target.style.transform = "scale(1)"
                              }}
                            >
                              <i
                                className="mdi mdi-file-plus"
                                style={{
                                  fontSize: "15px",
                                  marginRight: "2.5px",
                                }}
                              ></i>
                              {props.t("Add New")}
                              <div
                                style={{
                                  position: "absolute",
                                  top: "0",
                                  left: "0",
                                  right: "0",
                                  bottom: "0",
                                  background:
                                    "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                  pointerEvents: "none",
                                }}
                              ></div>
                            </Button>
                          </Col>
                        </>
                      )}

                      <Col lg={6} className="mb-2 mt-3">
                        <Label>
                          Packages
                        </Label>
                        <Select
                          ref={refSelectGame}
                          value={selected_Packages}
                          isMulti
                          onChange={(select) => {
                            setSelected_Packages(select);
                            getWallet();
                            setGrandTotal(Math.ceil(select?.reduce((total, pkge) => total + pkge.price, 0)))
                            setRemaining(Math.ceil(select?.reduce((total, pkge) => total + pkge.price, 0)))
                          }}
                          options={allPackages}
                          // className="basic-multi-select"
                          // classNamePrefix="select"
                          closeMenuOnSelect={false}
                          isDisabled={packageValidation}
                          required
                        />

                      </Col>





                    </Row>


                    {!isExistingBooking && (
                      <>
                        <Row>

                          <Col lg={4} className="mt-4">
                            <Label>Select Payment Method</Label>
                            <span
                              style={{
                                fontSize: "10px",

                                visibility: `${ selectedPaymentMethods[0]?.label==="Wallet"
                                  ? "unset"
                                  : "hidden"
                                  }`,
                              }}
                              className="text-muted ms-2"
                            >
                              {props.t("Balance")}:
                              {"  "}
                              <span
                                style={{
                                  color: "red",
                                  fontWeight: "600",
                                }}
                              >
                                {selected_Customer?.wallet}
                              </span>
                            </span>
                            <Select
                              options={paymentMethods.map(
                                method => ({
                                  value: method._id,
                                  label: method.name,
                                  image_url: method.image_url,
                                })
                              )}
                              // placeholder={props.t(
                              //   "Select Payment Method"
                              // )}
                              isMulti
                              value={selectedPaymentMethods}
                              onChange={handlePaymentMethodChange}
                              isDisabled={selected_Customer?.customer_type === 'VIP' || selected_Packages?.length <= 0 ? true : false}
                            />
                          </Col>
                          <br />


                          <Col lg={2}
                            style={{ paddingTop: "22px" }}
                          >
                            <AvField
                              name="remarks"
                              label={props.t("Remarks")}
                              placeholder="Enter Remarks (Optional)"
                              type="text"
                              value={remarks}
                              onChange={e =>
                                setRemarks(e.target.value)
                              }
                              validate={{
                                required: { value: false },
                              }}
                            // errorMessage={props.t("Select Game")}
                            />
                          </Col>

                          <Col lg={2}>
                            <span
                              style={{
                                fontSize: "10px",

                                visibility: `${discountTracker &&
                                  postdiscountAmount > 0
                                  ? "unset"
                                  : "hidden"
                                  }`,
                              }}
                              className="text-muted"
                            >
                              {props.t("Amount Reduced By")}:
                              {"  "}
                              <span
                                style={{
                                  color: "red",
                                  fontWeight: "600",
                                }}
                              >
                                {postdiscountAmount}
                              </span>
                            </span>
                            <div
                              style={{
                                height: "30px",
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                gap: "18px",
                              }}
                            >
                              <Label>Coupon</Label>
                              <Button
                                style={{
                                  width: "100px", marginTop: "-10px", height: "25px", justifyContent: "center", boxShadow:
                                    "none",
                                  transition: "transform 0.3s ease",
                                }}
                                onMouseEnter={e => {
                                  e.target.style.transform = "scale(1.05)"
                                }}
                                onMouseLeave={e => {
                                  e.target.style.transform = "scale(1)"
                                }}
                                className="btn btn-info btn-sm "
                                onClick={applyDiscount}
                                disabled={discountTracker}
                              >
                                Apply Coupon
                              </Button>
                            </div>

                            <AvField
                              id="discountInput"
                              name="discount"
                              // label={props.t("Discount Coupon")}
                              type="text"
                              placeholder={props.t(
                                "Enter Coupon Code"
                              )}
                              value={discountInput}
                              onChange={e =>
                                setDiscountInput(e.target.value)
                              }
                            />
                          </Col>



                          <Col lg={2}
                            style={{ paddingTop: "20px" }}
                          >
                            <AvField
                              id="paidAmount"
                              name="paidAmount"
                              label={props.t("Paid Amount")}
                              type="number"
                              placeholder={props.t("0")}
                              value={calculateFinalPaidAmount()}
                              disabled={true}
                              onChange={e =>
                                setPaidAmount(e.target.value)
                              }

                            />
                          </Col>

                          <Col lg={2} style={{ paddingTop: "20px" }}>
                              <AvField
                                id="paidAmount"
                                name="paidAmount"
                                label={props.t("Change Amount")}
                                type="number"
                                placeholder={props.t("0")}
                                value={isNoChange ? 0 :changeAmount}
                                onChange={handleChangeAmount()}
                                disabled={true}
                              />
                            </Col>

                        </Row>
                        <Row style={{marginTop:"50px"}}>
                        {selectedPaymentMethods.map(
                            selectedOption => (
                              <>
                                <Col
                                  lg={3}
                                  key={selectedOption.value}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <img
                                      src={selectedOption.image_url}
                                      alt={selectedOption.label}
                                      style={{
                                        marginRight: "4px",
                                        width: "21px",
                                        height: "21px",
                                      }}
                                    />
                                    <label>{`Paid by ${selectedOption.label}`}</label>
                                  </div>
                                  <AvField
                                    onWheel={preventValueChangeOnScroll}
                                    name={`paymentMethod_${selectedOption.value}`}
                                    errorMessage={props.t("Please Enter Price")}
                                    type="number"
                                    placeholder="0"
                                    value={
                                      formFieldValues[
                                      `paymentMethod_${selectedOption.value}`
                                      ] 
                                    }
                                    validate={{
                                      required: { value: true },
                                      pattern: {
                                        value: "^[0-9a-zA-Z]+$",
                                        errorMessage: props.t(
                                          "Cannot use space/special characters."
                                        ),
                                      },
                                    }}
                                    onChange={e => {
                                      const fieldName = `paymentMethod_${selectedOption.value}`;
                                      const newValue = parseFloat(e.target.value);
                                      setFormFieldValues(prevValues => ({
                                        ...prevValues,
                                        [fieldName]: newValue,
                                      }));

                                      setPaymentDetails(prevDetails => {
                                        const paymentMethodId = selectedOption.value;
                                        const paymentMethodName = selectedOption.label;
                                        const updatedDetails = [...prevDetails];
                                        const existingDetailIndex = updatedDetails.findIndex(
                                          detail => detail._id === paymentMethodId
                                        );

                                        if (existingDetailIndex !== -1) {
                                          updatedDetails[existingDetailIndex][paymentMethodName] = newValue;
                                        } else {
                                          const newDetail = {
                                            _id: paymentMethodId,
                                            [paymentMethodName]: newValue,
                                          };
                                          updatedDetails.push(newDetail);
                                        }
                                        const totalPaidAmount = updatedDetails.reduce(
                                          (total, paymentMethod) => {
                                            for (const key in paymentMethod) {
                                              if (key !== "_id") {
                                                total +=
                                                  parseFloat(paymentMethod[key]) || 0
                                              }
                                            }
                                            return total
                                          },
                                          0
                                        )
                                        let Total = final_amount ? final_amount : grandTotal
                                        const newRemainingPaidAmount = Total - totalPaidAmount - parseFloat(paid_by_wallet)
                                        setRemaining(newRemainingPaidAmount.toFixed(2))
                                        return updatedDetails;
                                      });
                                    }}
                                  />
                                </Col>


                              </>
                            )
                          )}
                        </Row>
                     

                        <Row>

                          <Col lg={5} >
                            <Label style={{ textAlign: "center", marginTop: "10px" }}>
                            Remaining Amount To Pay: <h5>
                                
                                  <span
                                    style={{
                                      color:remaining <= 0
                                      ? "#36B94D"
                                      : "#FA395D",
                                      fontWeight: "bold",
                                      marginLeft: "5px",
                                    }}
                                  >
                                 {selected_Customer?.customer_type === "VIP" ? "QR 0" :`QR ${remaining == 0
                                  ? ""
                                  : remaining < 0
                                    ? "+"
                                    : ""
                                }${Math.abs(remaining)} ${remaining < 0? "": ""}` }
                                  </span>
                                </h5>
                            </Label>
                          </Col>

                          <Col lg={5}>
                            <FormGroup
                              check
                              style={{ display: selected_Customer?.customer_type === "VIP" ? "none" : "flex", justifyContent: "start" }}
                            >
                             
                             
                              <Label check className="">
                                <Input
                                  style={{ border: "0.5px solid black" }}
                                  type="checkbox"
                                  checked={isNoChange}
                                  onChange={e =>
                                    setIsNoChange(e.target.checked)
                                  }
                                />
                                {props.t("No Change")}
                              </Label>
                            </FormGroup>
                          </Col>
                         

                          <Col style={{ display: "flex", justifyContent: "end", marginRight: "15px" }}>
                            <Label style={{ textAlign: "center", marginTop: "10px" }}>
                              Total <br /> Amount <br /> <h5>Qr <span style={{ fontWeight: "bold" }}>{selected_Customer?.customer_type === "VIP" ? 0 : grandTotal}</span> </h5>
                            </Label>
                          </Col>


                        </Row>
                        {/* <Row>
                          {final_amount && (

                            <Col lg={3}>

                              <Label>Amount to be Paid : &nbsp;<span style={{ color: "black", fontWeight: "bold", marginLeft: "15px" }}> {final_amount}</span> &nbsp;Qr</Label>

                            </Col>
                          )}

                        </Row> */}
                      </>
                    )}



                    <Row>


                      <Button
                        style={{
                          width: "124px",
                          margin: "auto",
                          boxShadow:
                            "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                          transition: "transform 0.3s ease",
                        }}
                        onMouseEnter={e => {
                          e.target.style.transform = "scale(1.05)"
                        }}
                        onMouseLeave={e => {
                          e.target.style.transform = "scale(1)"

                        }}
                        type="submit"
                        className="btn btn-dark waves-effect"
                        disabled={selectedPaymentMethods?.length<=0}
                      // onClick={handleValidSubmit()}
                      >
                        <i className="bx bx-cart  font-size-18 align-middle me-2"></i>{" "}
                        Check Out
                      </Button>



                    </Row>




                    {/* <Row>
                      <Col lg={12} style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>

                        <Button type="submit" className="btn btn-info" disabled={selected_Packages?.length<=0}>
                          Buy Now
                        </Button>

                      </Col>

                    </Row> */}
                  </AvForm>

                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row >
            <Col lg={12}>
              <Card>
                <CardBody
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  <CardTitle>
                    <Row>
                      <Col lg={6}>
                        {props.t("All Packages")}
                      </Col>
                    </Row>
                  </CardTitle>
                  <ToolkitProvider
                    keyField="_id"
                    columns={columns}
                    data={allPackages && allPackages}
                    search
                  >
                    {propst => (
                      <div>
                        <AvForm>
                          <Row>
                            <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                              <SearchBar {...propst.searchProps} style={{ width: "340px" }} />
                            </Col>
                          </Row>
                        </AvForm>
                        <BootstrapTable

                          {...propst.baseProps}
                          noDataIndication={props.t("No data to display.")}
                          striped
                          hover
                          condensed
                          pagination={paginationFactory(options)}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>

          </Row>

          <Modal style={{ marginTop: "auto" }}
            size="xl"
            isOpen={editModal}
            toggle={() => setEditModal(prev => prev = !prev)}
          >
            <ModalHeader toggle={() => setEditModal(prev => prev = !prev)}>
              {selectedRow.package_name}
            </ModalHeader>
            <ModalBody>
              {allPackages && (

                <>
                  <Row>
                    <Col md="3">
                      <Nav pills className="flex-column">
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              "mb-2": true,
                              active: verticalActiveTab === "1",
                            })}
                            onClick={() => {
                              toggleVertical("1");
                            }}
                          >
                            Items
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              "mb-2": true,
                              active: verticalActiveTab === "2",
                            })}
                            onClick={() => {
                              toggleVertical("2");
                            }}
                          >
                            Games
                          </NavLink>
                        </NavItem>


                      </Nav>
                    </Col>
                    <Col md="9">
                      <TabContent
                        activeTab={verticalActiveTab}
                        className="text-muted mt-4 mt-md-0"
                      >
                        <TabPane tabId="1">
                          <ToolkitProvider
                            keyField="_id"
                            columns={PackageItemDetails}
                            data={package_Item_Details && package_Item_Details}
                            search
                          >
                            {propst => (
                              <div>
                                <AvForm>
                                  <Row>
                                    <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                                      <SearchBar {...propst.searchProps} style={{ width: "340px" }} />
                                    </Col>
                                  </Row>
                                </AvForm>
                                <BootstrapTable

                                  {...propst.baseProps}
                                  noDataIndication={props.t("No data to display.")}
                                  striped
                                  hover
                                  condensed
                                  pagination={paginationFactory(options)}
                                />
                              </div>
                            )}
                          </ToolkitProvider>

                        </TabPane>
                        <TabPane tabId="2">
                          {/* Game Details */}
                          <ToolkitProvider
                            keyField="_id"
                            columns={PackageGameDetails}
                            data={package_Game_Details && package_Game_Details}
                            search
                          >
                            {propst => (
                              <div>
                                <AvForm>
                                  <Row>
                                    <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                                      <SearchBar {...propst.searchProps} style={{ width: "340px" }} />
                                    </Col>
                                  </Row>
                                </AvForm>
                                <BootstrapTable

                                  {...propst.baseProps}
                                  noDataIndication={props.t("No data to display.")}
                                  striped
                                  hover
                                  condensed
                                  pagination={paginationFactory(options)}
                                />
                              </div>
                            )}
                          </ToolkitProvider>

                        </TabPane>

                      </TabContent>
                    </Col>
                  </Row>
                </>
              )}
            </ModalBody>
          </Modal>


          {/* Add New Customer */}
          <Modal
            size="md"
            isOpen={addCustomerModal}
            toggle={() => setAddCustomerModal(prev => (prev = !prev))}
          >
            <ModalHeader toggle={() => setAddCustomerModal(prev => (prev = !prev))}>
              {`Add New Customer`}
            </ModalHeader>
            <div>
              <ModalBody>
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardBody>
                        <AvForm onValidSubmit={handleAddCustomer} ref={refContainer}>
                          <Row>
                            <Col lg={6}>
                              <AvField
                                label={props.t("Name")}
                                className="form-control"
                                type="text"
                                name="name"
                                placeholder="Enter Name"
                                validate={{ required: { value: true } }}
                                errorMessage={props.t("Name cannot be empty")}
                              />
                            </Col>
                            <br />
                            <Col lg={6}>
                              <AvField
                                label={props.t("Mobile")}
                                className="form-control"
                                type="number"
                                name="mobile"
                                placeholder="Enter Phone Number"
                                validate={{
                                  required: { value: true },
                                  maxLength: {
                                    value: 13,
                                    errorMessage: props.t(
                                      "Maximum 13 characters allowed."
                                    ),
                                  },
                                }}
                                errorMessage={props.t(
                                  "Phone number cannot be empty"
                                )}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={6}>
                              <AvField
                                label={props.t("Customer Type")}
                                className="form-control"
                                type="select"
                                name="customer_type"
                                defaultValue="Regular"
                                placeholder="Select Customer Type"
                              // validate={{ required: { value: true } }}
                              // errorMessage={props.t("Customer Type cannot be empty")}
                              >
                                <option value="">{props.t("--Select Type--")}</option>
                                <option value="Regular">{props.t("Regular")}</option>
                                <option value="Friend">{props.t("Friend")}</option>
                                <option value="VIP">{props.t("VIP")}</option>
                              </AvField>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              lg={12}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "10px",
                              }}
                            >
                              <Button
                                type="submit"
                                style={{
                                  position: "relative",
                                  overflow: "hidden",
                                  backgroundColor: "#fdd005",
                                  border: "none",
                                  color: "black",
                                  fontSize: "15px",
                                  fontWeight: "500",
                                  width: "150px",

                                  margin: "auto",
                                  boxShadow:
                                    "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                  transition: "transform 0.3s ease",
                                }}
                                onMouseEnter={e => {
                                  e.target.style.transform = "scale(1.05)"
                                }}
                                onMouseLeave={e => {
                                  e.target.style.transform = "scale(1)"
                                }}
                              >
                                {props.t("Add Customer")}
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "0",
                                    left: "0",
                                    right: "0",
                                    bottom: "0",
                                    background:
                                      "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                    pointerEvents: "none",
                                  }}
                                ></div>
                              </Button>
                            </Col>
                          </Row>
                        </AvForm>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

              </ModalBody>
            </div>
          </Modal>


        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(connect(null, {})(withTranslation()(BuyPackages)))
